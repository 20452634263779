import React from "react";
import { useNavigate } from "react-router-dom";

const Privacy = (props) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.togglePrivacy();
    navigate("/Settings");
  };
  // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
  // const formattedDate = new Date().toLocaleDateString('en-US', options);
  return (
    <div className="absolute top-0  left-0 w-full h-full">
      <div
        onClick={props.toggleTerms}
        className="w-full h-full z-5 bg-gray-500 opacity-40"
      ></div>
      <div className="absolute top-0 left-0 right-0 w-full  md:w-8/12 mt-24 mx-auto py-2 pl-4 pr-2  rounded h-5/6 z-10 bg-white text-black flex flex-col justify-between">
        <div>
          <h1 className="font-bold text-lg my-4">PRAIRI Privacy Policy</h1>
        </div>
        <hr />
        <div className="w-full h-full overflow-y-auto mb-4">
          <h2 className="italic mt-4">Last revision: 17 November 2023</h2>
          <section>
            <div className="max-full ">
              This privacy policy will explain how PRAIRI uses the personal data
              we collect from you when you use our website.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">Topics</h1>
            <div className="max-full ">
              <ul className="list-disc list-inside">
                <li>What data do we collect?</li>
                <li>How do we collect your data?</li>
                <li>How will we use your data?</li>
                <li>How do we store your data?</li>
                <li>Marketing</li>
                <li>What are your data protection rights?</li>
                <li>What are cookies?</li>
                <li>How do we use cookies?</li>
                <li>What types of cookies do we use?</li>
                <li>How to manage your cookies</li>
                <li>Privacy policies of other websites</li>
                <li>Changes to our privacy policy</li>
                <li>How to contact us</li>
                <li>How to contact the appropriate authorities</li>
              </ul>
              <br/>
            </div>
          </section>{" "}
          <section>
            <h1 className="font-bold text-lg my-4">What data do we collect?</h1>
            <div className="max-full ">
              PRAIRI collects the following data:
              
              <ul className="list-disc list-inside">
                <li>
                  Personal identification information (Name, email address,
                  phone number, etc.)
                </li>
                <li>
                  PRAIRI also collects information about your business, which
                  you provide to us, so that we can match you to relevant
                  individuals and/or companies.
                </li>
              </ul>
              <br/>
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              How do we collect your data?
            </h1>
            <div className="max-full ">
              You directly provide PRAIRI with most of the data we collect. We
              collect data and process data when you:
              <ul className="list-disc list-inside">
                <li>Register online and/or fill out a ‘Data Profile’.</li>
                <li>
                  Voluntarily complete a customer survey or provide feedback.
                </li>
                <li>Use or view our website via your browser’s cookies.</li>
              </ul>
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              How will we use your data?
            </h1>
            <div className="max-full ">
              PRAIRI collects your data so that we can:
              <ul className="list-disc list-inside">
                <li>Match you to relevant individuals and/or companies.</li>
                <li>
                  Email/notify you when there is a match or other related or
                  relevant information.
                </li>
                <li>
                  Email/notify you with special offers on other products and
                  services we think you might like.
                </li>
              </ul>
              <br></br>
              If you opted in as part of your Data Profile and agreed, PRAIRI
              will share your data with our partner companies so that they may
              offer you their products and services.
              <ul className="list-disc list-inside">
                <li>Companies offering valuable services to startups</li>
              </ul>
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              How do we store your data?
            </h1>
            <div className="max-full ">
              PRAIRI securely stores your data on European servers guarded with
              Helmet and Growler security protocols.
              <br></br>
              <br></br>
              PRAIRI will keep your data for as long as you keep your account.
              At any time, you may go to your profile settings and choose to
              delete your profile and data, as you have the right ‘to be
              forgotten’. Once this time period has expired, we will make
              reasonable effort to immediately delete your data but at least
              within 10-14 days.
            </div>
          </section>{" "}
          <section>
            <h1 className="font-bold text-lg my-4">Marketing</h1>
            <div className="max-full ">
              PRAIRI would like to send you information about products and
              services of ours that we think you might like, as well as those of
              our partner companies.
              <br></br>
              <br></br>
              If you have agreed to receive such marketing, you may always opt
              out at a later date.
              <br></br>
              <br></br>
              You have the right at any time to stop PRAIRI from contacting you
              for marketing purposes
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              What are your data protection rights?
            </h1>
            <div className="max-full ">
              <p>
                PRAIRI would like to make sure you are fully aware of all of
                your data protection rights.
              </p>
              <br/>
              <p>
                <strong>The right to access</strong> – You have the right to
                request PRAIRI for copies of your personal data. We may charge
                you a fee for this service.
              </p>
              <br/>
              <p>
                <strong>The right to rectification</strong> – You have the right
                to request that PRAIRI correct any information you believe is
                inaccurate. You also have the right to request PRAIRI to
                complete the information you believe is incomplete.
              </p>
              <br/>
              <p>
                <strong>The right to erasure</strong> – You have the right to
                request that PRAIRI erase your personal data, under certain
                conditions.
              </p>
              <br/>
              <p>
                <strong>The right to restrict processing</strong> – You have the
                right to request that PRAIRI restrict the processing of your
                personal data, under certain conditions.
              </p>
              <br/>
              <p>
                <strong>The right to object to processing</strong> – You have
                the right to object to PRAIRI’s processing of your personal
                data, under certain conditions.
              </p>
              <br/>
              <p>
                <strong>The right to data portability</strong> – You have the
                right to request that PRAIRI transfer the data that we have
                collected to another organization, or directly to you, under
                certain conditions.
              </p>
              <br/>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us at our email:
              </p>
<br/>
              <p>Write to us: <a
                className="underline text-blue-700 font-bold"
                href="mailto:contact@prairi.com"
              >
                contact@prairi.com
              </a></p>
            </div>
          </section>{" "}
          <section>
            <h1 className="font-bold text-lg my-4">Cookies</h1>
            <div className="max-full ">
              Cookies are text files placed on your computer to collect standard
              Internet log information and visitor behavior information. When
              you visit our websites, we may collect information from you
              automatically through cookies or similar technology
              <br />
              <br />
              For further information, visit allaboutcookies.org.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">How do we use cookies?</h1>
            <div className="max-full ">
              PRAIRI uses cookies in a range of ways to improve your experience
              on our website, including:
              <br/>
              <br/>
              <ul className="list-disc list-inside">
                <li>Keeping you signed in</li>
                <li>Understanding how you use our website</li>
                <li>Allowing for your easy access to match results</li>
              </ul>
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              What types of cookies do we use?
            </h1>
            <div className="max-full ">
              There are a number of different types of cookies, however, our
              website uses:
              <br/><br/>
              <ul className="list-disc list-inside">
                <li>
                  Functionality – PRAIRI uses these cookies so that we recognize
                  you on our website and remember your previously selected
                  preferences. These could include what language you prefer and
                  location you are in. A mix of first-party and third-party
                  cookies are used.
                </li>
                <li>
                  Advertising – PRAIRI uses these cookies to collect information
                  about your visit to our website, the content you viewed, the
                  links you followed, and information about your browser,
                  device, and your IP address. PRAIRI may, in the future, share
                  some limited aspects of this data with third parties for
                  advertising purposes. We may also share online data collected
                  through cookies with our advertising partners. This means that
                  when you visit another website, you may be shown advertising
                  based on your browsing patterns on our website.
                </li>
              </ul>
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">How to manage cookies</h1>
            <div className="max-full ">
              You can set your browser not to accept cookies, and the above
              website tells you how to remove cookies from your browser.
              However, in a few cases, some of our website features may not
              function as a result.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              Privacy policies of other websites
            </h1>
            <div className="max-full ">
              The PRAIRI website contains links to other websites. Our privacy
              policy applies only to our website, so if you click on a link to
              another website, you should read their privacy policy.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">
              Changes to our privacy policy
            </h1>
            <div className="max-full ">
              PRAIRI keeps its privacy policy under regular review and places
              any updates on this web page. This privacy policy was last updated
              on 17 November 2023.
            </div>
          </section>
          <section>
            <h1 className="font-bold text-lg my-4">How to contact us</h1>
            <div className="max-full ">
              If you have any questions about PRAIRI’s privacy policy, the data
              we hold on you, or you would like to exercise one of your data
              protection rights, please do not hesitate to contact us. Email us
              at:               <a
                className="underline text-blue-700 font-bold"
                href="mailto:contact@prairi.com"
              >
                contact@prairi.com
              </a>
            </div>
          </section>{" "}
        </div>
        <div>
          <hr />
          <input
            type="button"
            className=" bg-blue-500 mt-4 cursor-pointer rounded border border-gray-500 p-2.5  text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
            value="Close"
            data-testid="terms"
            onClick={props.togglePrivacy}
          ></input>
          {/**This button is not functional yet. When functionality is added, please uncomment the part of the test associated to it */}
        </div>
      </div>
    </div>
  );
};

export default Privacy;
