export const industrySelectOptions = [
  "AdTech/Media",
  "Big Data",
  "Consumer Goods",
  "Cybersecurity",
  "Deep Tech (AI, Web3, Blockchain, etc.)",
  "Emerging Tech (NFTs, VR, Robotics)",
  "eCommerce",
  "EdTech",
  "Entertainment/Music/Gaming",
  "Fashion",
  "FoodTech",
  "Fintech/Finance",
  "Greentech/Cleantech/Energy",
  "HealthTech/MedTech",
  "IT/Software/SaaS",
  "PropTech",
  "Retail",
  "Service Industry Solutions",
  "Space Technology",
  "Transport and Logistics (Shared Mobility/Maritime/Delivery Services)",
];
export const stageSelectOptions = [
  "Early/Seed",
  "Growth",
  "Series A",
  "Series B/Higher",
];

export const modelSelectOptions = [
  "B2C",
  "B2B",
];

export const productTypeSelectOptions = [
  "Software",
  "Hardware",
  "SaaS",
  "Other",
];
