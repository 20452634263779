import { navBarContext, authContext } from "./context/context";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useContext, useState } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import ThankYou from "../assets/thankyou-unicorn.png";

import {
  updateLatestActions,
  addToSummary,
  pitchTracking,
  urlTracking,
  videoPitchTracking,
  useWindowDimensions,
  sendBugEmails,
} from "../utils";
import {
  handleInterested,
  handleArchived,
  handleNotInterested,
  handleNotInterestedNoFeedback,
  handleFlagging,
} from "../utils/MatchesHandling";
import TextBubble from "../assets/unicornnext.png";
import Flag from "../assets/flag.png";
import {
  NewStartup,
  Modal,
  PrairiButton,
  TextButton,
  Loading,
  FlagLink,
  Viewer,
  RoundedTitle,
  PleaseLogin,
  BugTab,
} from "@peterfosso/prairi-components";
import { useMediaQuery } from "react-responsive";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  updateDoc,
  addDoc,
} from "../firebase/firebaseApp";
import axios from "axios";
import { data } from "autoprefixer";
//hi

const NewMatch = ({ docId: docIdProp, navBarMargin: marginProp }) => {
  const { currentUser } = useContext(authContext);
  const { navBarHeight } = useContext(navBarContext);
  let { docId } = useParams();
  const actualDocId = docIdProp || docId;
  const actualNavBarMargin = marginProp || navBarHeight;
  let navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [matchesData, setMatchesData] = useState([]);
  const [matches, setMatches] = useState([]);
  const [userData, setUserData] = useState(null);
  const userRef = doc(db, "users", actualDocId);
  const [isFirst, setIsFirst] = useState(false);
  const [control, setControl] = useState(true);
  const [thankYouPopUp, setThankYouPopUp] = useState(false);
  const [sawButtons, setSawButtons] = useState(false);
  const [profileViewed, setProfileViewed] = useState(false);
  const [potentialName, setPotentialName] = useState("");
  const [potentialEmail, setPotentialEmail] = useState("");
  const [potentialPhone, setPotentialPhone] = useState("");
  const [viewerSrc, setViewerSrc] = useState("");
  const [shareError, setShareError] = useState("");
  const [tySharePopup, setTySharePopup] = useState(false);
  const { width, height } = useWindowDimensions();
  const deviceWidth = parseFloat(width);
  const deviceHeight = parseFloat(height);
  const time = Timestamp.fromDate(new Date());
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState("");
  const [bugError, setBugError] = useState(null);

  const redirectTo404 = () => {
    navigate("/404");
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    updateLatestActions(userRef, "New match view", isMobile);
  }, []);

  useEffect(() => {
    if (potentialPhone !== "" || potentialEmail !== "") {
      setShareError(false);
    }
  }, [potentialEmail, potentialPhone]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement || document.body;
      setSawButtons(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (sawButtons && !profileViewed) {
      //addToSummary(matches[0], "Viewed profile", docId);
      setProfileViewed(true);
    }
  }, [sawButtons, profileViewed]);

  const getDataFromDb = async (actualDocId) => {
    try {
      const userRef = doc(db, "users", actualDocId);
      const userDoc = await getDoc(userRef);
      if (currentUser) {
        if (userDoc.exists()) {
          if (currentUser.uid === userDoc.data().uid) {
            setUserData(userDoc.data());
            if (
              !userDoc.data().interestedMatches &&
              !userDoc.data().notInterestedMatches &&
              !userDoc.data().archivedMatches
            ) {
              setIsFirst(true);
            }
            if (userDoc.data().pendingMatches) {
              setMatches(userDoc.data().pendingMatches.reverse());

              const startupDataPromises = userDoc
                .data()
                .pendingMatches.reverse()
                .map(async (startupId) => {
                  const startupDocRef = doc(db, "users", startupId);
                  const startupDoc = await getDoc(startupDocRef);

                  if (startupDoc.exists()) {
                    return {
                      ...startupDoc.data(),
                      id: startupDoc.id,
                    };
                  }

                  return null; // Handle the case where the document doesn't exist
                });

              const startupData = await Promise.all(startupDataPromises);
              const filteredData = startupData.filter(
                (startup) => startup && !startup.paused
              );
              setMatchesData(filteredData); // Filter out null values
              if (filteredData.length < 1) {
                navigate("/dashboard");
              }
              setViewerSrc(matchesData[0].formData.company.deck);
            }
          } else {
            redirectTo404();
            return;
          }
        } else {
          //console.log("redirected from no currentUser")
          redirectTo404();
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (actualDocId, matches) => {
    try {
      await getDataFromDb(actualDocId);
      if (dataFetched && matchesData && matchesData.length === 0) {
        navigate("/thatsall");
      }
      setDataFetched(true);
    } catch (error) {}
  };

  useEffect(() => {
    if (currentUser && actualDocId) {
      fetchData(actualDocId, matches);
    }
  }, [currentUser, actualDocId, matchesData.length]);

    useEffect(() => {
      fetchData();
      if (!currentUser) {
        navigate("/login");
      }
    }, [currentUser]);
  

  const [interestedPopUp, setInterestedPopUp] = useState(false);
  const [archivedPopUp, setArchivedPopUp] = useState(false);
  const [notInterestedPopUp, setNotInterestedPopUp] = useState(false);
  const [flagPopUp, setFlagPopUp] = useState(false);
  const [notInterestedReasons, setNotInterestedReasons] = useState([]);
  const [flagReason, setFlagReason] = useState(null);
  const [textReason, setTextReason] = useState("");

  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    return true;
  };

  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        userData.email,
        userData.contactName,
        bugText,
        "NewMatch",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  const handleInterestedClick = () => {
    setInterestedPopUp(true);
  };

  const handleArchivedClick = () => {
    setArchivedPopUp(true);
  };

  const handleNotInterestedClick = () => {
    setNotInterestedPopUp(true);
  };

  const handleFirstOk = (userId) => {
    setControl(false);
    updateLatestActions(userRef, "Clicked OK first match popup", isMobile);
  };

  const handlePitchTracking = (startupId, investorId, isMobile, viewerSrc) => {
    if (isMobile) {
      window.open(viewerSrc, "_blank");
    }
    pitchTracking(startupId, investorId, isMobile);
    setViewerSrc(viewerSrc);
  };

  const handleVideoPitchTracking = (
    startupId,
    investorId,
    isMobile,
    viewerSrc
  ) => {
    videoPitchTracking(startupId, investorId, isMobile);
    if (!viewerSrc.includes("youtube") || isMobile) {
      const absoluteVideoPitch = viewerSrc
        ? /^https?:\/\//.test(viewerSrc)
          ? viewerSrc
          : `http://${viewerSrc}`
        : "";
      window.open(absoluteVideoPitch, "_blank");
      return;
    }
    setViewerSrc(viewerSrc);
  };

  const handleNotInterestedReason = (event) => {
    const selectedValue = event.target.value;
    if (event.target.checked) {
      if (selectedValue !== "other") {
        setNotInterestedReasons([...notInterestedReasons, selectedValue]);
      }
    } else {
      setNotInterestedReasons(
        notInterestedReasons.filter((value) => value !== selectedValue)
      );
    }
  };

  const handleFlagReason = (event) => {
    const selectedValue = event.target.value;
    setFlagReason(selectedValue);
  };

  const handleNotInterestedReasonText = (event) => {
    const checkbox = document.getElementById("other");
    checkbox.checked = true;
    setTextReason(event.target.value);
  };

  if (isMobile) {
    return (
      <div
        id="newMatch__main"
        style={{
          marginTop: actualNavBarMargin,
          marginBottom: actualNavBarMargin,
          width: "100%",
          minWidth: "366px",
        }}
        className=""
      >
        {!dataFetched && (
          <div className="pt-20">
            <Loading />
          </div>
        )}
        {dataFetched && (
          <div className="flex justify-center">
            <section
              id="newMatch__container"
              className={`
           max-w-[560px] 
  
          m-auto  rounded-md
          min-w-[380px]
         `}
            >
              {matchesData.length > 0 ? (
                <div className="relative mx-2 p-0.5 bg-rainbowBg mt-5 ">
                  <div className="absolute top-[-15px] left-1/2 transform -translate-x-1/2">
                    <RoundedTitle color="black" label="New Match" />
                  </div>
                  <div className="">
                    <NewStartup
                      startup={matchesData[0]}
                      interested={() => handleInterestedClick()}
                      notInterested={() => handleNotInterestedClick()}
                      archive={() => handleArchivedClick()}
                      title="NEW MATCH"
                      isMobile={isMobile}
                      pitchTracking={() =>
                        handlePitchTracking(
                          matches[0],
                          actualDocId,
                          isMobile,
                          matchesData[0].formData.company.deck
                        )
                      }
                      state={
                        userData.formData.company.startupstates.length < 49
                      }
                      urlTracking={() =>
                        urlTracking(matches[0], actualDocId, isMobile)
                      }
                      videoPitchTracking={() =>
                        handleVideoPitchTracking(
                          matches[0],
                          actualDocId,
                          isMobile,
                          matchesData[0].formData.company.videoPitch
                        )
                      }
                    />
                  </div>

                  <div className="w-1/2">
                    <Modal border="yellow" open={archivedPopUp}>
                      <div className=" m-3">
                        <p className="mb-4 ">
                          {" "}
                          OK, we’ll file this match away in your ‘Archive’
                          folder so you can decide later.
                        </p>
                        <p>
                          TIP: But it’s better if you instead choose
                          ‘Interested’ (if you are) since other investors may
                          also be receiving this match.
                        </p>
                      </div>
                      <div className="mt-2 flex justify-between items-center  w-[80%]">
                        <PrairiButton
                          bgColor="regular"
                          border="yellow"
                          size="md"
                          rounded="full"
                          label="Archive"
                          click={() =>
                            handleArchived(
                              matchesData[0].id,
                              actualDocId,
                              setMatches,
                              userRef,
                              setMatchesData,
                              matchesData,
                              isMobile,
                              setArchivedPopUp
                            )
                          }
                        />
                        <TextButton
                          label="undo"
                          click={() => setArchivedPopUp(false)}
                        />
                      </div>
                    </Modal>
                  </div>

                  <div className="w-1/2">
                    <Modal border="green" open={interestedPopUp}>
                      <div className=" m-3">
                        <p>
                          {" "}
                          Great! We’ll let them know and provide you with their
                          contact info.
                        </p>
                      </div>
                      <div className="flex justify-between items-center  w-[80%]">
                        <PrairiButton
                          bgColor="regular"
                          border="green"
                          size="md"
                          rounded="full"
                          label="OK"
                          click={() =>
                            handleInterested(
                              matchesData[0].id,
                              actualDocId,
                              setMatches,
                              userRef,
                              setMatchesData,
                              matchesData,
                              isMobile,
                              setInterestedPopUp
                            )
                          }
                        />
                        <TextButton
                          label="undo"
                          click={() => setInterestedPopUp(false)}
                        />
                      </div>
                    </Modal>
                  </div>

                  <div className="w-1/2">
                    <Modal border="red" open={notInterestedPopUp}>
                      <div>
                        <div className="flex justify-center items-center mb-5 mt-2">
                          <FlagLink click={() => setFlagPopUp(true)} />
                        </div>
                        <form>
                          <label for="checkboxes-group">
                            To help this startup so that they may one day be
                            ready to receive investment from you, please provide
                            your feedback.
                            <span className="font-bold ml-1">
                              You will remain anonymous.
                            </span>
                          </label>
                          <br />
                          <div className="flex mt-4 items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="no-traction"
                              name="checkboxes-group"
                              value="Not enough traction"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="no-traction">
                              {" "}
                              Not enough traction
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="team-not-strong"
                              name="checkboxes-group"
                              value="Team not strong enough"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="team-not-strong">
                              {" "}
                              Team not strong enough
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="bad-timing"
                              name="checkboxes-group"
                              value="Bad timing for this idea or market"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="bad-timing">
                              {" "}
                              Bad timing for this idea or market
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-center">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="competitive-landscape"
                              name="checkboxes-group"
                              value="Landscape is too competitive"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="competitive-landscape">
                              {" "}
                              Landscape is too competitive
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-start">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="higher-capital"
                              name="checkboxes-group"
                              value="Much higher capital would be needed to succeed"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="higher-capital">
                              {" "}
                              Much higher capital would be needed
                            </label>
                          </div>
                          <br />
                          <div className="flex  items-start">
                            <input
                              className="mr-5  w-5 h-5"
                              type="checkbox"
                              id="other"
                              name="checkboxes-group"
                              value="other"
                              onChange={handleNotInterestedReason}
                            />
                            <label for="other">
                              {" "}
                              Other reason:{" "}
                              <textarea
                                type="text"
                                className="w-full mt-1  h-auto rounded-lg text-black min-h-[80px] resize-both"
                                id="other-reason"
                                name="other-reason"
                                onChange={handleNotInterestedReasonText}
                              />
                            </label>
                          </div>
                        </form>
                        <div className="my-5">
                          <TextButton
                            label="Don't wish to leave feedback"
                            click={() =>
                              handleNotInterestedNoFeedback(
                                matchesData[0].id,
                                actualDocId,
                                setMatches,
                                setMatchesData,
                                matchesData,
                                userRef,
                                isMobile,
                                setNotInterestedPopUp
                              )
                            }
                          />
                        </div>
                        <div className="flex justify-between ml-4 items-center  w-[80%]">
                          <PrairiButton
                            bgColor="regular"
                            border="red"
                            size="md"
                            rounded="full"
                            label="Send"
                            click={() =>
                              handleNotInterested(
                                matchesData[0].id,
                                actualDocId,
                                notInterestedReasons,
                                textReason,
                                setMatches,
                                setMatchesData,
                                matchesData,
                                userRef,
                                isMobile,
                                setNotInterestedPopUp
                              )
                            }
                          />

                          <div className="ml-3">
                            <TextButton
                              label="undo"
                              click={() => setNotInterestedPopUp(false)}
                            />
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <div className="w-1/2">
                      <Modal
                        border="no-border"
                        open={isFirst && control}
                        size="full"
                      >
                        <div className="flex justify-center items-center w-full flex-col bg-backgroundPrairi pb-4 px-4 rounded-xl">
                          <div
                            className="flex justify-end items-end w-full text-lighterGray mb-2 cursor-pointer"
                            onClick={() => handleFirstOk(actualDocId)}
                          >
                            x
                          </div>
                          <img
                            src={TextBubble}
                            alt="Deal Tracker, click on the dots to track your deals' progress"
                          />
                        </div>
                      </Modal>
                    </div>
                    <Modal border="red" open={flagPopUp}>
                      <div className=" m-3">
                        <form>
                          <img
                            src={Flag}
                            className="h-12 mx-auto block mb-5"
                            alt="flag"
                          />
                          <label for="checkboxes-group " className="text-sm">
                            <div className="font-bold ">
                              Report this profile and we'll look into it.
                            </div>
                            PRAIRI personally checks each user before allowing
                            them to join, but it's possible this one slipped
                            past us.
                          </label>
                          <div className="flex flex-col mt-5">
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="fake-profile"
                                name="flagReason"
                                value="Fake profile"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="fake-profile">Fake profile</label>
                            </div>
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="scammer"
                                name="flagReason"
                                value="Scammer"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="fake-profile">Scammer</label>
                            </div>
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="abusive"
                                name="flagReason"
                                value="Abusive to you or others"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="fake-profile">
                                Abusive to you or others
                              </label>
                            </div>
                            <div className="flex mb-4">
                              <input
                                type="radio"
                                id="other"
                                name="flagReason"
                                value="Other"
                                onChange={handleFlagReason}
                                className="mr-3 w-5"
                              />
                              <label htmlFor="other">Other</label>
                              <input
                                type="text"
                                className="w-52 rounded-lg text-black ml-3"
                                id="otherReason"
                                name="otherReason"
                                onChange={(event) =>
                                  setFlagReason(event.target.value)
                                }
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="flex w-[80%] justify-between items-center">
                        <PrairiButton
                          bgColor="regular"
                          border="red"
                          size="md"
                          rounded="full"
                          label="Send"
                          click={() =>
                            handleFlagging(
                              matchesData[0].id,
                              flagReason,
                              actualDocId,
                              setMatches,
                              setMatchesData,
                              matchesData,
                              userRef,
                              isMobile,
                              setFlagPopUp,
                              setThankYouPopUp
                            )
                          }
                        />

                        <div className="ml-3">
                          <TextButton
                            label="back"
                            click={() => setFlagPopUp(false)}
                          />
                        </div>
                      </div>
                    </Modal>
                  </div>
                  <Modal border="no-border" open={thankYouPopUp}>
                    <div className="py-5">
                      <img
                        src={ThankYou}
                        alt="Thank you"
                        className="w-[80%] mx-auto mb-6"
                      />
                      <div className="flex place-content-center">
                        <PrairiButton
                          bgColor="regular"
                          border="silver"
                          size="sm"
                          rounded="soft"
                          label="OK"
                          click={() => setThankYouPopUp(false)}
                        />
                      </div>
                    </div>
                  </Modal>
                </div>
              ) : (
                !currentUser && (
                  <div className="mt-20">
                    <PleaseLogin click={redirectToLogin} />
                  </div>
                )
              )}
              {/* {matchesData && matchesData.length===0 && navigate("/thatsall")} */}
              {!dataFetched && <Loading />}
            </section>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        id="newMatch__main"
        style={{
          marginTop: actualNavBarMargin,
          marginBottom: actualNavBarMargin,
          width: "100%",
          minWidth: "366px",
        }}
        className=""
      >
        {userData && (
          <BugTab
            email={userData.email}
            name={userData.contactName}
            setEmail={handleBugEmail}
            setText={handleBugText}
            submit={(file) => handleUploadAndSendEmail(file)}
            error={bugError}
            handleFileChange={handleUpload}
          />
        )}
        {!dataFetched && (
          <div className="pt-20">
            <Loading />
          </div>
        )}
        {dataFetched && (
          <div className="flex justify-center">
            <section
              id="newMatch__container"
              className={`

  
          m-auto  rounded-md
          min-w-[380px]
         `}
            >
              {matchesData.length > 0 ? (
                <div className="flex ">
                  <div className="relative mx-2 px-0.5 pt-0.5 bg-rainbowBg mt-5 w-1/3 ">
                    <div className="absolute top-[-15px] z left-1/2 transform -translate-x-1/2 z-9999">
                      <RoundedTitle color="black" label="New Match" />
                    </div>
                    <div
                      className="overflow-y-auto"
                      style={{ maxHeight: deviceHeight * 0.8 }}
                    >
                      <NewStartup
                        startup={matchesData[0]}
                        interested={() => handleInterestedClick()}
                        notInterested={() => handleNotInterestedClick()}
                        archive={() => handleArchivedClick()}
                        title="NEW MATCH"
                        isMobile={isMobile}
                        pitchTracking={() =>
                          handlePitchTracking(
                            matches[0],
                            actualDocId,
                            isMobile,
                            matchesData[0].formData.company.deck
                          )
                        }
                        state={
                          userData.formData.company.startupstates.length < 49
                        }
                        urlTracking={() =>
                          urlTracking(matches[0], actualDocId, isMobile)
                        }
                        videoPitchTracking={() =>
                          handleVideoPitchTracking(
                            matches[0],
                            actualDocId,
                            isMobile,
                            matchesData[0].formData.company.videoPitch
                          )
                        }
                        viewer={
                          viewerSrc.includes("firebasestorage")
                            ? "deck"
                            : "video"
                        }
                      />
                    </div>

                    <div className="w-1/2">
                      <Modal border="yellow" open={archivedPopUp}>
                        <div className=" m-3">
                          <p className="mb-4 ">
                            {" "}
                            OK, we’ll file this match away in your ‘Archive’
                            folder so you can decide later.
                          </p>
                          <p>
                            TIP: But it’s better if you instead choose
                            ‘Interested’ (if you are) since other investors may
                            also be receiving this match.
                          </p>
                        </div>
                        <div className="mt-2 flex justify-between items-center  w-[80%]">
                          <PrairiButton
                            bgColor="regular"
                            border="yellow"
                            size="md"
                            rounded="full"
                            label="Archive"
                            click={() =>
                              handleArchived(
                                matchesData[0].id,
                                actualDocId,
                                setMatches,
                                userRef,
                                setMatchesData,
                                matchesData,
                                isMobile,
                                setArchivedPopUp
                              )
                            }
                          />
                          <TextButton
                            label="undo"
                            click={() => setArchivedPopUp(false)}
                          />
                        </div>
                      </Modal>
                    </div>

                    <div className="w-1/2">
                      <Modal border="green" open={interestedPopUp}>
                        <div className=" m-3">
                          <p>
                            {" "}
                            Great! We’ll let them know and provide you with
                            their contact info.
                          </p>
                        </div>
                        <div className="flex justify-between items-center  w-[80%]">
                          <PrairiButton
                            bgColor="regular"
                            border="green"
                            size="md"
                            rounded="full"
                            label="OK"
                            click={() =>
                              handleInterested(
                                matchesData[0].id,
                                actualDocId,
                                setMatches,
                                userRef,
                                setMatchesData,
                                matchesData,
                                isMobile,
                                setInterestedPopUp
                              )
                            }
                          />
                          <TextButton
                            label="undo"
                            click={() => setInterestedPopUp(false)}
                          />
                        </div>
                      </Modal>
                    </div>

                    <div className="w-1/2">
                      <Modal border="red" open={notInterestedPopUp}>
                        <div>
                          <div className="flex justify-center items-center mb-5 mt-2">
                            <FlagLink click={() => setFlagPopUp(true)} />
                          </div>
                          <form>
                            <label for="checkboxes-group">
                              To help this startup so that they may one day be
                              ready to receive investment from you, please
                              provide your feedback.
                              <span className="font-bold">
                                You will remain anonymous.
                              </span>
                            </label>
                            <br />
                            <div className="flex mt-4 items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="no-traction"
                                name="checkboxes-group"
                                value="Not enough traction"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="no-traction">
                                {" "}
                                Not enough traction
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="team-not-strong"
                                name="checkboxes-group"
                                value="Team not strong enough"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="team-not-strong">
                                {" "}
                                Team not strong enough
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="bad-timing"
                                name="checkboxes-group"
                                value="Bad timing for this idea or market"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="bad-timing">
                                {" "}
                                Bad timing for this idea or market
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-center">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="competitive-landscape"
                                name="checkboxes-group"
                                value="Landscape is too competitive"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="competitive-landscape">
                                {" "}
                                Landscape is too competitive
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-start">
                              <input
                                className="mr-5  w-5 h-5"
                                type="checkbox"
                                id="higher-capital"
                                name="checkboxes-group"
                                value="Much higher capital would be needed to succeed"
                                onChange={handleNotInterestedReason}
                              />
                              <label for="higher-capital">
                                {" "}
                                Much higher capital would be needed to succeed
                              </label>
                            </div>
                            <br />
                            <div className="flex  items-start">
                              <div className="flex  items-start">
                                <input
                                  className="mr-5  w-5 h-5"
                                  type="checkbox"
                                  id="other"
                                  name="checkboxes-group"
                                  value="other"
                                  onChange={handleNotInterestedReason}
                                />
                                <label for="other">
                                  {" "}
                                  Other reason:{" "}
                                  <textarea
                                    type="text"
                                    className="w-full mt-1  h-auto rounded-lg text-black min-h-[80px] resize-both"
                                    id="other-reason"
                                    name="other-reason"
                                    onChange={handleNotInterestedReasonText}
                                  />
                                </label>
                              </div>
                            </div>
                          </form>
                          <div className="my-5">
                            <TextButton
                              label="Don't wish to leave feedback"
                              click={() =>
                                handleNotInterestedNoFeedback(
                                  matchesData[0].id,
                                  actualDocId,
                                  setMatches,
                                  setMatchesData,
                                  matchesData,
                                  userRef,
                                  isMobile,
                                  setNotInterestedPopUp
                                )
                              }
                            />
                          </div>
                          <div className="flex justify-between ml-4 items-center  w-[80%]">
                            <PrairiButton
                              bgColor="regular"
                              border="red"
                              size="md"
                              rounded="full"
                              label="Send"
                              click={() =>
                                handleNotInterested(
                                  matchesData[0].id,
                                  actualDocId,
                                  notInterestedReasons,
                                  textReason,
                                  setMatches,
                                  setMatchesData,
                                  matchesData,
                                  userRef,
                                  isMobile,
                                  setNotInterestedPopUp
                                )
                              }
                            />

                            <div className="ml-3">
                              <TextButton
                                label="undo"
                                click={() => setNotInterestedPopUp(false)}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <div className="w-1/2">
                      <Modal
                        border="no-border"
                        open={isFirst && control}
                        size="full"
                      >
                        <div className="flex justify-center items-center w-full flex-col bg-backgroundPrairi pb-2 px-2 rounded-xl">
                          <div
                            className="flex justify-end items-end w-full text-lighterGray mb-2 cursor-pointer"
                            onClick={() => handleFirstOk(actualDocId)}
                          >
                            x
                          </div>
                          <img
                            src={TextBubble}
                            className="w-[70%]"
                            alt="Deal Tracker, click on the dots to track your deals' progress"
                          />
                        </div>
                      </Modal>
                      </div>
                      <Modal border="red" open={flagPopUp}>
                        <div className=" m-3">
                          <form>
                            <img
                              src={Flag}
                              className="h-12 mx-auto block mb-5"
                              alt="flag"
                            />
                            <label for="checkboxes-group " className="text-sm">
                              <div className="font-bold ">
                                Report this profile and we'll look into it.
                              </div>
                              PRAIRI personally checks each user before allowing
                              them to join, but it's possible this one slipped
                              past us.
                            </label>
                            <div className="flex flex-col mt-5">
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="fake-profile"
                                  name="flagReason"
                                  value="Fake profile"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="fake-profile">
                                  Fake profile
                                </label>
                              </div>
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="scammer"
                                  name="flagReason"
                                  value="Scammer"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="fake-profile">Scammer</label>
                              </div>
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="abusive"
                                  name="flagReason"
                                  value="Abusive to you or others"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="fake-profile">
                                  Abusive to you or others
                                </label>
                              </div>
                              <div className="flex mb-4">
                                <input
                                  type="radio"
                                  id="other"
                                  name="flagReason"
                                  value="Other"
                                  onChange={handleFlagReason}
                                  className="mr-3 w-5"
                                />
                                <label htmlFor="other">Other</label>
                                <input
                                  type="text"
                                  className="w-52 rounded-lg text-black ml-3"
                                  id="otherReason"
                                  name="otherReason"
                                  onChange={(event) =>
                                    setFlagReason(event.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="flex w-[80%] justify-between items-center">
                          <PrairiButton
                            bgColor="regular"
                            border="red"
                            size="md"
                            rounded="full"
                            label="Send"
                            click={() =>
                              handleFlagging(
                                matchesData[0].id,
                                flagReason,
                                matches
                              )
                            }
                          />

                          <div className="ml-3">
                            <TextButton
                              label="back"
                              click={() => setFlagPopUp(false)}
                            />
                          </div>
                        </div>
                      </Modal>
                    </div>
                    <Modal border="no-border" open={thankYouPopUp}>
                      <div className="py-5">
                        <img
                          src={ThankYou}
                          alt="Thank you"
                          className="w-[80%] mx-auto mb-6"
                        />
                        <div className="flex place-content-center">
                          <PrairiButton
                            bgColor="regular"
                            border="silver"
                            size="sm"
                            rounded="soft"
                            label="OK"
                            click={() => setThankYouPopUp(false)}
                          />
                        </div>
                      </div>
                    </Modal>
                  </div>
                  <div className="w-2/3 pt-5 ml-4">
                    {dataFetched && matchesData && (
                      <Viewer src={viewerSrc} h={deviceHeight * 0.8} />
                    )}
                  </div>
                </div>
              ) : (
                !currentUser && (
                  <div className="mt-20">
                    <PleaseLogin click={redirectToLogin} />
                  </div>
                )
              )}
              {/* {matchesData && matchesData.length===0 && navigate("/thatsall")} */}
              {!dataFetched && <Loading />}
            </section>
          </div>
        )}
      </div>
    );
  }
};

export default NewMatch;
