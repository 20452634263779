import React from "react";
import TransitionUpwardElement from "./TransitionUpwardElement";
import { useNavigate } from "react-router-dom";
const SectionThreeLandingPage = ({
  sectionThreeLandingPageProps: { toggleCodeMenu },
}) => {
  let navigate = useNavigate();
  return (
    <section className="w-full bg-lastSection bg-cover bg-right-bottom px-2 py-4 md:text-lg lg:pb-36 lg:pt-16  lg:text-xl">
      <div className="w-full lg:w-8/12 mx-auto">
        {" "}
        <TransitionUpwardElement
          elementType="h1"
          elementClassNames="text-3xl animate-transitionIn my-12 lg:my-16 uppercase font-bold md:text-5xl"
        >
          P r a i r i
        </TransitionUpwardElement>{" "}
        <TransitionUpwardElement
          elementType="p"
          elementClassNames="my-12 animate-transitionIn"
        >
          <span className="font-bold">PRAIRI</span> rhymes with 'very' and we take matching
          VERY seriously. With PRAIRI, you no longer need to waste precious time with those
          who don't match exactly what you're looking for. Pitching is no longer limited to
          the investors and startups in the room or your immediate circle. Find your perfect
          match locally, in neighboring countries, or on the other side of the world.
        </TransitionUpwardElement>{" "}
        <TransitionUpwardElement
          elementType="p"
          elementClassNames="my-12 animate-transitionIn"
        >
          <span className="font-bold">Startups:</span> Match with the right
          investors - automatically.
        </TransitionUpwardElement>{" "}
        <TransitionUpwardElement
          elementType="p"
          elementClassNames="my-12 animate-transitionIn"
        >
          <span className="font-bold">Investors:</span> We know your time is valuable and that
          you realize that your next investment might come from just about anywhere. PRAIRI
          delivers specific startups that exactly match what you’re looking for, based on the
          criteria you select, such as location, industry, stage of development, traction,
          investment amount, and more. PRAIRI empowers you to <span className="font-semibold">find startups without having to
            search for them</span>, and connects you to the latest and greatest opportunities.
        </TransitionUpwardElement>{" "}
        {" "}
        <TransitionUpwardElement
          elementType="p"
          elementClassNames="my-12 animate-transitionIn"
        >
          <q className="italic">
            Founders, we celebrate you and all you have to give. Together, we can all light the fire
            that ignites the future.
          </q>{" "}
          – Peter Fosso (Founder, PRAIRI)
        </TransitionUpwardElement>{" "}

        <TransitionUpwardElement
          elementType="p"
          elementClassNames="my-12 animate-transitionIn"
        >
          <span className="font-semibold">PRAIRI... </span>because startups should spend their time building their product and growing their company
          – not on finding and pitching to investors.
          {" "}

        </TransitionUpwardElement>{" "}

        <TransitionUpwardElement
          elementType="p"
          elementClassNames="my-12 animate-transitionIn"
        >
          Join now and start matching!
        </TransitionUpwardElement>
        <div className="flex items-center animate-transitionIn  w-full   py-8  text-white text-lg lg:text-2xl  ">
          <TransitionUpwardElement
            elementType="button"
            id='landing-startup'
            onClick={() => navigate("/getfunded")}
            elementClassNames="py-3  px-12 rounded-full  bg-startupBtn hover:bg-investorBtn mr-4"
          >
            Startup
          </TransitionUpwardElement>
          <TransitionUpwardElement
            elementType="button"
            id='landing-investor'
            onClick={() => navigate("/discover")}
            elementClassNames="py-3  px-12 rounded-full bg-startupBtn hover:bg-investorBtn ml-4 "
          >
            Investor
          </TransitionUpwardElement>
        </div>
      </div>
    </section>
  );
};

export default SectionThreeLandingPage;