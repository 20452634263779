import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Rocket from "../assets/stage.gif";
import {
  LandingHassle,
  LandingFundraising,
  LandingAngel,
  LandingReimagined,
  HowItWorks,
MessageSlider,
IndustryCarousel
} from "@peterfosso/prairi-components";
import { navBarContext } from "./context/context";
import Phone from "../assets/matchphone.png";
import Map from 'react-map-gl/mapbox';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useWindowDimensions } from "../utils"
import { db, getDoc, doc, updateDoc } from "../firebase/firebaseApp";

const AdLanding = () => {
  let navigate = useNavigate();
  const sectionRef = useRef(null);
  const { width, height } = useWindowDimensions();
  const deviceWidth = parseFloat(width);
  const deviceHeight = parseFloat(height);
    let { docId } = useParams() || null;
    const [data, setData] = useState({
      username: "",
    });
  const [loading, setLoading] = useState(true);
    const handleClick = () => {
      if (docId){
        navigate(`/invited/${docId}`)
      }
      else{
        navigate(`/invitation/startup/stripe`)
      }
    }
  const handleScroll = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const sliderMessages = [<div><span className="font-bold mr-1">New Feature! </span> Pitch decks display automatically to investors on a PC/laptop</div>,
    <div className="flex"><img alt="rocket icon" src={Rocket} className="w-[20px] h-[20px] mr-2"/> New! Check out our new <span className="font-bold mx-1">deal tracking</span> feature!</div>
]
const sliderColors = ["#303030", "#204549"]

  useEffect(() => {
    const fetchDocumentData = async () => {
      if (docId){
        try {
          const docRef = doc(db, "preUsers", docId); //now fetches from preUsers so it can read
          const docData = await getDoc(docRef);
          if (docData.exists()) {
            const docInfo = docData.data();
  setData({ username: docInfo.username || "" });
          } else {
            //console.log("No such document!");
          }
          setLoading(false);
        } catch (error) {
          //console.error("Error fetching document:", error);
          setLoading(false);
        }
      }
      else{
        data.username = "";
      }

    };

    fetchDocumentData();
  }, [docId]);

const [viewport, setViewPort] = useState({
  latitude: 0,
  longitude: 0,
  zoom: 1
})
  const { navBarHeight } = useContext(navBarContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="p-0 w-full">
       <div className="mb-4">
       <MessageSlider colors={sliderColors} messages={sliderMessages} interval={4000}/>
        </div>
      <LandingHassle click={handleClick} how={handleScroll} name={!loading && data.username ? `${data.username.split(" ")[0]}` : false}/>
        {/* <div className="w-full flex justify-center">
        <Map
      // https://visgl.github.io/react-map-gl/docs/get-started/mapbox-tokens
      mapboxAccessToken="pk.eyJ1IjoiZ2F0b2xhaWxhIiwiYSI6ImNtN3Ywb2I5MDA3M3cybG9qcDN4eHk4dXoifQ.EqN5d2ovtoMeBF9ba_cNLA"
      initialViewState={viewport}
      style={{width: deviceWidth, height: deviceHeight/2}}
      mapStyle="mapbox://styles/mapbox/streets-v9"
    />
        </div> */}

<IndustryCarousel/>
<div ref={sectionRef}>
      <HowItWorks click={handleClick}/>
        </div>
      <LandingReimagined click={handleClick}/>
      <LandingAngel  click={handleClick}/>
      <LandingFundraising  click={handleClick}/>
    </div>
  );
};

export default AdLanding;