import { getDoc, updateDoc, Timestamp,} from "firebase/firestore";
import {  query, collection, db, where, getDocs, addDoc, doc } from "./firebase/firebaseApp";
import { useState, useEffect } from 'react';
import axios from "axios";
import { getFunctions, httpsCallable } from "firebase/functions";
const MAX_ACTIONS = 10;
const time = Timestamp.fromDate(new Date());

export const updateLatestActions = async (userRef, activity, isMobile) => {
    
    const userDoc = await getDoc(userRef);
    let latest = userDoc.data()?.latest || [];

    // If 'latest' is not in the correct format, initialize it as an empty array
    if (!Array.isArray(latest) || latest.some(item => typeof item !== 'object' || !item.timestamp || !item.activity)) {
        latest = [];
    }
    let device = isMobile ? "Mobile" : "Computer or tablet";
    // Add the new action to the beginning of the array
    latest.unshift({ timestamp: time, activity, device });

    // Trim the array to the maximum length
    latest = latest.slice(0, MAX_ACTIONS);

    // Update the document with the new 'latest' array
    await updateDoc(userRef, { latest });
};

export const addToSummary = async (startupId, activity, investorId) => {
  const startupRef = doc(db, "users", startupId);
  const startupDoc = await getDoc(startupRef);
  const time = new Date();
  const formattedDate = `${time.toLocaleString('en-us', { month: 'long' })} ${time.getDate()}, ${time.getFullYear()}`;

  let summary = startupDoc.data()?.summary || [];
  const existingDateIndex = summary.findIndex(item => item.date === formattedDate);

  if (existingDateIndex !== -1) {
    // If the date already exists in the summary, check if the investor already exists
    const existingInvestorIndex = summary[existingDateIndex].logs.findIndex(log => log.investor === investorId);
    if (existingInvestorIndex !== -1) {
      // If the investor already exists, push the activity to their activities array
      summary[existingDateIndex].logs[existingInvestorIndex].activities.push(activity);
    } else {
      // If the investor doesn't exist, add a new object for them with the activity
      summary[existingDateIndex].logs.push({ investor: investorId, activities: [activity] });
    }
  } else {
    // If the date doesn't exist, add a new object for the date with the investor and activity
    summary.push({
      date: formattedDate,
      logs: [{ investor: investorId, activities: [activity] }]
    });
  }

  // Update the document with the updated summary
  await updateDoc(startupRef, { summary });
};




export const pitchTracking = async (startupId, investorId, isMobile ) => {
  const startupRef = doc(db, "users", startupId);
  const startupDoc = await getDoc(startupRef);
  const investorRef = doc(db, "users", investorId);
  updateLatestActions(investorRef, `Viewed pitch deck for ${startupDoc.data().formData.company.company}`, isMobile)
  addToSummary(startupId, `Viewed pitch deck`, investorId)
}

export const urlTracking = async (startupId, investorId, isMobile ) => {
  const startupRef = doc(db, "users", startupId);
  const startupDoc = await getDoc(startupRef);
  const investorRef = doc(db, "users", investorId);
  updateLatestActions(investorRef, `Viewed website for ${startupDoc.data().formData.company.company}`, isMobile)
  addToSummary(startupId, `Viewed website`, investorId)
}

export const videoPitchTracking = async (startupId, investorId, isMobile ) => {
  const startupRef = doc(db, "users", startupId);
  const startupDoc = await getDoc(startupRef);
  const investorRef = doc(db, "users", investorId);
  updateLatestActions(investorRef, `Viewed video pitch deck for ${startupDoc.data().formData.company.company}`, isMobile)
  addToSummary(startupId, `Viewed video pitch deck`, investorId)
}

export const phoneTrackingStartup = async (startupId, investorId, isMobile ) => { //tracks investor viewing startup's phone
  const startupRef = doc(db, "users", startupId);
  const startupDoc = await getDoc(startupRef);
  const investorRef = doc(db, "users", investorId);
  updateLatestActions(investorRef, `Viewed phone number for ${startupDoc.data().formData.company.company}`, isMobile)
}

export const phoneClickTrackingStartup = async (startupId, investorId, isMobile ) => { //tracks investor viewing startup's phone
  const startupRef = doc(db, "users", startupId);
  const startupDoc = await getDoc(startupRef);
  const investorRef = doc(db, "users", investorId);
  updateLatestActions(investorRef, `Clicked phone number for ${startupDoc.data().formData.company.company}`, isMobile)
}

export const emailClickTrackingStartup = async (startupId, investorId, isMobile ) => { //tracks investor viewing startup's phone
  //console.log("email click tracking")
  const startupRef = doc(db, "users", startupId);
  const startupDoc = await getDoc(startupRef);
  const investorRef = doc(db, "users", investorId);
  updateLatestActions(investorRef, `Clicked email for ${startupDoc.data().formData.company.company}`, isMobile)
}

export const emailTrackingStartup = async (startupId, investorId, isMobile ) => { //tracks investor viewing startup's email
  const startupRef = doc(db, "users", startupId);
  const startupDoc = await getDoc(startupRef);
  const investorRef = doc(db, "users", investorId);
  updateLatestActions(investorRef, `Viewed email for ${startupDoc.data().formData.company.company}`, isMobile)
}

export const contactTrackingStartup = async (startupId, investorId, isMobile ) => { //tracks investor viewing startup's email
  const startupRef = doc(db, "users", startupId);
  const startupDoc = await getDoc(startupRef);
  const investorRef = doc(db, "users", investorId);
  updateLatestActions(investorRef, `Clicked on view contact for ${startupDoc.data().formData.company.company}`, isMobile)
}

export const phoneTrackingInvestor= async (startupId, investorId, isMobile ) => { //tracks startup viewing investor's phone
 // console.log("phone tracking")
  const startupRef = doc(db, "users", startupId);
  const investorRef = doc(db, "users", investorId);
  const investorDoc = await getDoc(investorRef)
  updateLatestActions(startupRef, `Viewed phone number for ${investorDoc.data().formData.company.company}`, isMobile)
}

export const emailTrackingInvestor= async (startupId, investorId, isMobile ) => { //tracks startup viewing investor's email
  const startupRef = doc(db, "users", startupId);
  const investorRef = doc(db, "users", investorId);
  const investorDoc = await getDoc(investorRef)
  updateLatestActions(startupRef, `Viewed email for ${investorDoc.data().formData.company.company}`, isMobile)
}

export const getDataFromUser = async (uid) => {
    //pass currentUser.uid when the moment comes
    try {
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      //console.log("getDataFromUser")
      return { userData, userId }; 

    } catch (error) {
      console.log(error);
      return null;
    }
  };

  export const getDataFromPotential = async (email) => {
    //pass currentUser.uid when the moment comes
    try {
      const q = query(collection(db, "potential"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      return { userData, userId }; 
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  export const updateButtonClickCount = async (buttonName) => {
    try {
      const q = query(collection(db, "events"), where("name", "==", buttonName));
      const querySnapshot = await getDocs(q);
      let eventData;
      querySnapshot.forEach((doc) => {
        eventData = doc.data();
        eventData.id = doc.id;
      });
  
      const currentMonthName = new Date().toLocaleString('default', { month: 'long' });
      
      if (!eventData || eventData.month !== currentMonthName) {
        // If eventData doesn't exist or the stored month is different from the current month
        await addDoc(collection(db, "events"), {
          name: buttonName,
          count: 1,
          month: currentMonthName
        });
      } else {
        const eventRef = doc(db, "events", eventData.id);
        await updateDoc(eventRef, {
          count: eventData.count + 1,
          month: currentMonthName
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  export const getEventByName = async (name) => {
    try {
      const q = query(collection(db, "events"), where("name", "==", name));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        return null; // No documents found
      }
      
      const eventData = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
  
      // If you only want to return the first document, use:
      // const eventData = { id: querySnapshot.docs[0].id, ...querySnapshot.docs[0].data() };
  
      return eventData[0];
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  export const getUsersCountByField = async (field, value) => {
    try {
      const q = query(collection(db, "users"), where(field, "==", value));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    } catch (error) {
      console.error("Error getting users count:", error);
      return 0;
    }
  };
  
  export const getUsersCountByMonth = async (monthName) => {
    try {
      // Convert month name to month number (0-indexed)
      const targetMonth = new Date(`${monthName} 1, 2000`).getMonth();
      
      // Query all users
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
  
      // Filter users by the specified month and year
      const usersInMonth = querySnapshot.docs.filter(doc => {
        const createdAt = doc.data().createdAt.toDate();
        return createdAt.getMonth() === targetMonth;
      });
  
      return usersInMonth.length;
    } catch (error) {
      console.error("Error getting users count by month:", error);
      return 0;
    }
  };
  
  export function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  
  
  

  export const getStartupsCountByMonth = async (monthName) => {
    try {
      // Query startups with category "startup"
      const q = query(collection(db, "users"), 
                      where("category", "==", "startup"));
      const querySnapshot = await getDocs(q);
      
      // Filter startups by the specified month
      const targetMonth = new Date(`${monthName} 1, 2000`).getMonth();
      const startupsInMonth = querySnapshot.docs.filter(doc => {
        const createdAt = doc.data().createdAt;
        if (createdAt instanceof Timestamp) {
          return createdAt.toDate().getMonth() === targetMonth;
        }
        return false;
      });
  
      return startupsInMonth.length;
    } catch (error) {
      console.error("Error getting startups count by month:", error);
      return 0;
    }
  };
  

  export const getInvestorsCountByMonth = async (monthName) => {
    try {

      const q = query(collection(db, "users"), 
                      where("category", "==", "investor"));
      const querySnapshot = await getDocs(q);
      
      const targetMonth = new Date(`${monthName} 1, 2000`).getMonth();
      const investorsInMonth = querySnapshot.docs.filter(doc => {
        const createdAt = doc.data().createdAt;
        if (createdAt instanceof Timestamp) {
          return createdAt.toDate().getMonth() === targetMonth;
        }
        return false;
      });
  
      return investorsInMonth.length;
    } catch (error) {
      console.error("Error getting investors count by month:", error);
      return 0;
    }
  };


  export const getBuildAStartupCountByMonth = async (monthName) => {
    try {
      // Query documents in "BuildAStartup" collection
      const q = query(collection(db, "BuildAStartup"));
      const querySnapshot = await getDocs(q);
      
      // Convert month name to month number (0-indexed)
      const targetMonth = new Date(`${monthName} 1, 2000`).getMonth();
  
      // Filter documents by the specified month
      const docsInMonth = querySnapshot.docs.filter(doc => {
        const createdAt = doc.data().createdAt;
        if (createdAt instanceof Timestamp) {
          return createdAt.toDate().getMonth() === targetMonth;
        }
        return false;
      });
  
      return docsInMonth.length;
    } catch (error) {
      console.error("Error getting BuildAStartup count by month:", error);
      return 0;
    }
  };
  
  
  export const fetchExchangeRates = async () => {
    try {
      const currencies = ["USD", "EUR", "SEK", "GBP"];
      const rates = {};
  
      for (let fromCurrency of currencies) {
        const response = await fetch(
          `https://api.getgeoapi.com/v2/currency/convert` +
            `?api_key=88d12c7a3871f7cf8ac1e8b7c372834adb55122e` +
            `&from=${fromCurrency}` +
            `&amount=1` +
            `&format=json`
        );
        const data = await response.json();
  
        if (data.status === 'success') {
          rates[fromCurrency] = {};
  
          for (let toCurrency in data.rates) {
            rates[fromCurrency][toCurrency] = parseFloat(data.rates[toCurrency].rate);
          }
        }
      }
  
      return rates;
    } catch (error) {
      console.error('Failed to fetch exchange rates:', error);
      return null;
    }
  };

  

  export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
  
      window.addEventListener('resize',
   handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  
  }

  
  export const sendBugEmail = async (email, text, component, screenshot) => {
    const functions = getFunctions();
    const sendEmailFn = httpsCallable(functions, "sendEmail");
    try{
      await sendEmailFn({
        templateId: 67,
        recipients: [{
          name: "Caro",
          email: "caro@prairi.com"
        }],
        params: {
          EMAIL: email || "",
          TEXT: text || "",
          COMPONENT: component || "",
          SCREENSHOT: screenshot || ""
        }
      })
    }
    catch (error){
      console.log(error)
    }
  }


  export const sendBugEmailConfirmation = async (email, text, name) => { //full name
    const functions = getFunctions();
    const sendEmailFn = httpsCallable(functions, "sendEmail");
    try{
      await sendEmailFn({
        templateId: 68,
        recipients: [          {
          name: name || "",
          email: email,
        }],
        params: {
          NAME: name?.split(" ")[0] || "",
          EMAIL: email,
          TEXT: text,
        }
      })
    }
    catch (error){
      console.log(error)
    }
  }


  export const sendBugEmails = async (email, name, text, component, screenshot) => {
    try {
      await sendBugEmail(email, text, component, screenshot);
      await sendBugEmailConfirmation(email, text, name);
    } catch (e) {
      console.error("Error sending bug emails:", e);
      throw e; 
    }
  };


  export const sendNotMeEmail = async (email) => { 
    const functions = getFunctions();
    const sendEmailFn = httpsCallable(functions, "sendEmail");
    try{
    await sendEmailFn({
      templateId: 69,
      recipients: [           {
        name: "Caro",
        email: "caro@prairi.com",
      }],
      params: {
        EMAIL: email,
      }
    })
  }
    catch (error){
      console.log(error)
    }
  }


  export const setMatchCurrentStatusInvestor = async (investorId, startupId, newStatus) => {
    try {
      const investorRef = doc(db, "users", investorId);
      const investorDoc = await getDoc(investorRef);
      if (investorDoc.exists()) {
        const data = investorDoc.data();
        const interestedMatches = data.interestedMatches || [];
        const updatedMatches = interestedMatches.map((match) => {
          if (match.id === startupId) {
            if (match.status===newStatus){
              return {...match, status: "unknown"}
            }
            else{
              return { ...match, status: newStatus };
            }
            
          }
          return match;
        });
        await updateDoc(investorRef, { interestedMatches: updatedMatches });
        await updateLatestActions(investorRef, `updated ${startupId} status to ${newStatus}`)
        return updatedMatches; // Devuelve los datos actualizados
      } else {
        console.error(`Investor con ID ${investorId} no existe.`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  export const setMatchCurrentStatusStartup = async (startupId, investorId, newStatus) => { 
    try {
      const startupRef = doc(db, "users", startupId);
      const startupDoc = await getDoc(startupRef);
      if (startupDoc.exists()) {
        const data = startupDoc.data();
        const investorContacts = data.investorContacts || [];
        const updatedMatches = investorContacts.map((match) => {
          if (match.id === investorId) {
            if (match.status===newStatus){
              return {...match, status: "unknown"}
            }
            else{
              return { ...match, status: newStatus };
            }
          }
          return match;
        });
        await updateDoc(startupRef, { investorContacts: updatedMatches });
        await updateLatestActions(startupRef, `updated ${investorId} status to ${newStatus}`)
        return updatedMatches; // Devuelve los datos actualizados
      } else {
        console.error(`startup con ID ${startupId} no existe.`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  export const createBooleanObject = (selectedArray, allOptions) => {
    return allOptions.reduce((acc, option) => {
        acc[option] = selectedArray.includes(option);
        return acc;
    }, {});
};
