import React, { useEffect, useContext, useState } from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, 
  FacebookIcon, LinkedinIcon, WhatsappIcon, WhatsappShareButton, TwitterIcon } from "react-share";
  import axios from "axios";
import { dataContext, authContext } from "../context/context";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions, generateRandomString } from "../../utils";
import { FaCopy } from 'react-icons/fa';
import Heart from "../../assets/stage.gif"
import Phone from "../../assets/phone.png";
import ProfileComplete from "../../assets/teleicon.png";
import Logo from "../../assets/prairiwhite.png";
import { useNavigate } from "react-router-dom";
import { Modal, TextButton, Rating, PrairiButton } from "@peterfosso/prairi-components";
import ConfettiExplosion from "react-confetti-explosion"
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
  addDoc,
} from "../../firebase/firebaseApp";
const Matching = () => {
  const time = Timestamp.fromDate(new Date())
  const { currentUser } = useContext(authContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [currentUserData, setCurrentUserData] = useState({});
  const [currentUserId, setCurrentUserId] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [rating, setRating] = useState(0)
  const [feedback, setFeedback] = useState("")
  const [promo, setPromo] = useState("")
  const [isExploding, setIsExploding] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const navigate = useNavigate();
  const handleRatingChange = async (value) => {
    setRating(value);
    try {
      let userRef = doc(db, "users", currentUserId);
    } catch (error) {
      console.log(error)
    }
};



    const sendLoginSMS = async (userData, userId) => { //Send ONLY ONCE. So we'll store that.
      try {
        const docRef = doc(db, "users", userId);
        await updateDoc(docRef, { loginSMS: true });
        const messagesCollection = collection(db, "messages");
        const dataToStore = {
          to: `+${userData.phone}`,
          body: `Hey, ${userData.contactName.split(' ')[0]}! Congratulations on completing your profile. Don't forget to log in on your phone :) http://prairi.com`,
        };
        await addDoc(messagesCollection, dataToStore);
      } catch (error) {
        console.log(error);
      }
    
    };


  const getDataFromUser = async (uid) => {
    //pass currentUser.uid when the moment comes
    try {
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      // console.log("userData", JSON.stringify(userData))
      if (!userData.promo) {
        const newPromo = "ST" + + '0123456789'.charAt(Math.floor(Math.random() * 10)) + generateRandomString(4); 
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, {
          promo: newPromo
        });
        const promoCodeRef = collection(db, "promoCodes");
        await addDoc(promoCodeRef, {
        code: newPromo,
        discount: 100,
        users: 0
      });
        // Update userData with new promo
        userData.promo = newPromo;
      }

      setCurrentUserData(userData);
      setCurrentUserId(userId);
      setIsExploding(!userData.explosion)

      // console.log("currentUserData inside getDataFromUser", currentUserData)
    } catch (error) {
      //console.log(error);
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(`Hey, our startup just joined PRAIRI, where you can find the right investors (without even looking for them). Check it out at https://prairi.com`)
      .then(() => {
        alert('Text copied!');
        storeShare(currentUserId, "copy/paste")
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };

  const fetchData = async () => {
    try {
      if (currentUser) {
        // Assuming currentUser is defined in your component
        await getDataFromUser(currentUser.uid); // Pass the UID from currentUser
        // console.log("interestedStartupData inside fetchData", interestedStartupsData)
        // console.log("archivedStartupData inside fetchData", archivedStartupsData)
        setDataFetched(true);
        if (!isMobile && currentUserData && !currentUserData.loginSMS) {
          sendLoginSMS(currentUserData, currentUserId);
        }
        if (currentUserData && !currentUserData.explosion){
          const docRef = doc(db, "users", currentUserId);
          await updateDoc(docRef, { explosion: true });
          
        }
      }
    } catch (error) {
      //console.log('error fetching data');
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentUser, currentUserData]);

  const storeShare = async (userId, media) => {
    const userRef = doc(db, "users", userId)
    updateLatestActions(userRef, `Shared ${media}`, isMobile)
  }


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  const {setDataFunc, globalData} = useContext(dataContext)
  const sharedMessage = `Hey, our startup just joined PRAIRI, where you can find the right investors (without even looking for them). Check it out at https://prairi.com`
  const url = "https://www.prairi.com"
  const facebookShareUrl = `https://www.prairi.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${sharedMessage}`;
  const [messagePopup, setMessagePopup] = useState(false)
  const data = globalData.formData
  const allowed = data.company.completed && data.industry.completed && data.founders.completed
                  && data.ownership.completed && data.support.completed && !globalData.pendingApproval
if (allowed){
  return (
    <div className="shadow-grey w-full  h-full rounded-md mt-2 md:max-w-xl mx-auto py-2.5  pb-[120px] ">
      
      <div className=" text-center  mt-5 ">
        <img
          className="h-24 mb-5 mx-auto items-center flex"
          src={Logo}
          alt="Matching"
        />
        
        <div className="font-bold text-center text-2xl  ">Matching Starts!</div>
        <p className="text-[#666666] flex justify-center  underline text-xs cursor-pointer" onClick={()=>navigate("/matchpause")}>Oops, don't match me quite yet</p>
      </div>
<div className="justify-center items-center flex">
{dataFetched && currentUserData && isExploding && <ConfettiExplosion />}
</div>
      <div className="flex gap-4 mt-10 items-center">

        <img src={ProfileComplete} className="w-16 h-16 " alt="tick" />
        <div className=" mr-3">
          <p className="w-full text-center items-center flex pr-12 ">
          We'll notify you via SMS and email whenever there's investor interest or feedback
          </p>


        </div>
      </div>

      <div className=" my-8 gap-4 flex">
        <img src={Heart} className="h-12 "/>
       {currentUserData &&  dataFetched &&<div className="w-full text-center mb-8 items-center flex pl-12 ">
       <div className="flex flex-col text-center justify-center items-center text-greenPrairi">
       <p>You can now close this page and go back to</p>
       <p>        growing {currentUserData.formData.company.company} into the next unicorn :)</p> </div>
</div>}
        
      </div>
            {!isMobile && (
          <div className="flex mt-8">
            <img src={Phone} className="w-16 h-16 ml-2" alt="tick" />
            <div className="ml-5 mr-3">
              <p className="font-bold mb-3">One final step <span className=" text-lg">...</span></p>
              <p className="mb-3 ">
                We just sent you an SMS (or may arrive soon)! Click the link to
                open PRAIRI on your mobile phone so you'll be logged in and
                ready to be notified whenever we have matches for you.
              </p>
            </div>
          </div>
        )}

      {/* <div className="mb-[500px] mt-5 text-silverPrairi  lg:flex items-center flex-col">
          <p className="mb-2  ml-[60px]">
            In the meantime... as we're a startup too, you can help us grow by <span className="font-bold">spreading the word!</span>
          </p>
          <p className="mb-5  ml-[60px]"> Just click any of the below links and copy/paste (or change) this pre-written <span className="underline font-bold cursor-pointer" onClick={()=>setMessagePopup(true)}>message.</span> It's fast and easy! Thanks in advance! :)</p>
          <div className="flex justify-between w-3/5 lg:w-2/5  mx-auto ">
            <TwitterShareButton url={url} title={sharedMessage} > 
              <TwitterIcon round size={32} onClick={()=>storeShare(currentUserId, "Twitter")}/>
            </TwitterShareButton>
            <FacebookShareButton url={facebookShareUrl}   >
              <FacebookIcon round size={32} onClick={()=>storeShare(currentUserId, "Facebook")}/>
            </FacebookShareButton>
            <LinkedinShareButton source={sharedMessage} url={url} title={sharedMessage} summary={sharedMessage}>
              <LinkedinIcon size={32} round onClick={()=>storeShare(currentUserId, "Linkedin")}/>
            </LinkedinShareButton>
            <WhatsappShareButton url={url} title={sharedMessage}>
              <WhatsappIcon size={32} round onClick={()=>storeShare(currentUserId, "Whatsapp")}/>
            </WhatsappShareButton>
          </div>
          <Modal open={messagePopup} border="silver" size="lg">
            <div className="pb-2 px-2">
              <div className="flex justify-end ">
              <button onClick={copyText} className="pr-5">
        <FaCopy />
      </button>
                <TextButton label="x" color="silver" click={()=>setMessagePopup(false)}/></div>
              <div><p>Hey, our startup just joined PRAIRI, where you can find the right investors (without even looking for them). Check it out at www.prairi.com </p> 
</div>
              
              
            </div>
          </Modal>
        </div> */}

    </div>
  );
}
else{
  return (
    <div className="shadow-grey w-full rounded-md mt-2 h-full md:max-w-xl mx-auto py-2.5 pb-[300px]">
    <div className="text-center mt-5">
      <img className="h-16 mb-1 mx-auto" src={Logo} alt="Matching" />
      <h1 className="font-bold text-center mb-8 text-base">Matching Pending</h1>
    </div>

<div className='flex'>

{!currentUserData.pendingApproval && dataFetched && <div className='ml-5 mr-3'>
<p className='font-bold mb-3'>Oops! Your profile is incomplete.</p>
<p className='mb-3 '>Please complete it so we can match your startup with investors from all around the world.</p>

</div>}
{/* <Modal open={currentUserData.pendingApproval && !currentUserData.sentForReview && currentUserData.formData && currentUserData.formData.matching.completed} size="full" border="silver">
  <div className="p-2 flex flex-col items-center">
  <img className="h-16 mb-1 mx-auto" src={Logo} alt="Matching" />
  <div className="my-2">Thank you for completing your Data Profile! We'll review the information and let you know when the Matching starts. </div>
  <PrairiButton label="OK" border="green" bgColor="regular" rounded="soft" size="sm" click={()=>handlePendingApproval()}/>
  </div>

</Modal> */}

</div>
  </div>
  )
}

};

export default Matching;
