import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "../assets/settings.png";
import { navBarContext, authContext } from "./context/context";
import { query, collection, db, where, getDocs } from "../firebase/firebaseApp";
import { auth, signOut } from "../firebase/firebaseApp";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
} from "react-share";
import { FaCopy } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import DataProfile from "./DataProfileStartup";
import ForgotPassword from "./ForgotPassword";
import Terms from "./common/Terms";
import Privacy from "./Privacy";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, doc } from "../firebase/firebaseApp";
import { BugTab, Modal, TextButton, LogoutConfirmation } from "@peterfosso/prairi-components";
import {
  sendBugEmails,
  updateLatestActions,
  generateRandomString,
} from "../utils";

const Settings = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
    const [logoutPopup, setLogoutPopup] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  const [bugError, setBugError] = useState(null);
  const [showShare, setShowShare] = useState(false);
  const [showShareMessage, setShowShareMessage] = useState(false);
  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const { email } = currentUser;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      if (userData) {
        setDataFetched(true);
        setUserData(userData);
        setCurrentUserId(userId);
      }
      return { userData, userId };
    } catch (error) {
      // console.log(error);
    }
  };

  const storeShare = async (userId, media) => {
    const userRef = doc(db, "users", userId);
    updateLatestActions(userRef, `Shared ${media}`, isMobile);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setLogoutPopup(false);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const copyText = () => {
    navigator.clipboard
      .writeText(
        `Hey, our startup just joined PRAIRI, where you can find the right investors (without even looking for them). Check it out at https://prairi.com`
      )
      .then(() => {
        alert("Text copied!");
        storeShare(currentUserId, "copy/paste");
      })
      .catch((error) => {
        console.error("Error copying text: ", error);
      });
  };

  const sharedMessage = `Hey, our startup just joined PRAIRI, where you can find the right investors (without even looking for them). Check it out at https://prairi.com`;
  const url = "https://www.prairi.com";
  const facebookShareUrl = `https://www.prairi.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}&quote=${sharedMessage}`;

  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    return true;
  };

  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        userData.email,
        userData.contactName,
        bugText,
        "Settings",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  const [showDataProfile, setShowDataProfile] = useState(false);
  const [userData, setUserData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const handleDataProfile = async () => {
    const { userData } = await getDataFromDb();
    setShowDataProfile(true);
    if (userData.category === "investor") navigate("/dataprofile/investor");
    else {
      navigate("/dataprofile/startup");
    }
  };
  const [showTerms, setShowTerms] = useState(false);
  const toggleTerms = () => {
    setShowTerms(!showTerms);
  };
  const [showPrivacy, setShowPrivacy] = useState(false);
  const togglePrivacy = () => {
    setShowPrivacy(!showPrivacy);
  };
  const toggleShare = () => {
    setShowShare(!showShare);
  };

  useEffect(() => {
    getDataFromDb();
    // console.log("data fetched?")
  }, [currentUser]);

  const redirectToLogin = () => {
    navigate("/login"); // Redirect to the login page
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (dataFetched && !currentUser) {
      redirectToLogin();
    }
  }, [currentUser]);

  const [showPassword, SetShowPassword] = useState(false);
  const handlePassword = () => {
    SetShowPassword(true);
    navigate("/forgotpassword");
  };
  const handleEditDataProfile = async () => {
    const { userData } = await getDataFromDb();
    if (userData.category === "startup") {
      navigate("/startupregistration");
    } else {
      navigate("/investorregistration");
    }
  };

  const handlePauseDataProfile = async () => {
    const { userData } = await getDataFromDb();
    if (userData.paused) {
      navigate("/reactivate");
    } else {
      navigate("/matchpause");
    }
  };

  const handleNotifications = () => {
    navigate("/notificationsettings");
  };
  if (dataFetched && userData && userData.seeking === "NOT SEEKING") {
    return (
      <div
        className={` w-full  md:max-w-2xl md:mx-auto border-customWhite  from-navStart to-navEnd  py-8 px-4`}
        style={{ marginTop: navBarHeight + 4, backgroundColor: "#262626 " }}
      >
        {showTerms && <Terms toggleTerms={toggleTerms} />}
        {showPrivacy && <Privacy togglePrivacy={togglePrivacy} />}
        {userData && (
          <BugTab
            email={userData.email}
            name={userData.contactName}
            setEmail={handleBugEmail}
            setText={handleBugText}
            submit={(file) => handleUploadAndSendEmail(file)}
            error={bugError}
            handleFileChange={handleUpload}
          />
        )}
        <div className="w-3/12 mx-auto mt-8 mb-4">
          <img className="w-full" src={SettingsIcon} alt="Settings icon" />
        </div>
        <h1 className="font-bold text-center w-full text-customWhite md:text-xl my-4">
          Account Settings{" "}
        </h1>

        <div className="flex flex-col items-center w-full my-4 text-customWhite md:text-lg ">
          <button onClick={handleNotifications} className="underline my-2">
            Notification settings
          </button>
          <button onClick={handlePassword} className="underline my-2">
            Change your password
          </button>
          <button onClick={toggleTerms} className="underline my-2">
            Read our Terms & Conditions
          </button>
          <button onClick={togglePrivacy} className="underline my-2">
            Privacy Policy
          </button>
          <button onClick={()=>setLogoutPopup(true)} className="underline my-2">
            Log out
          </button>
        </div>
              <Modal open={logoutPopup} size="md">
                <LogoutConfirmation
                  stay={() => setLogoutPopup(false)}
                  logout={handleLogout}
                />
              </Modal>
      </div>
    );
  } else {
    return (
      <div
        className={` w-full  md:max-w-2xl md:mx-auto border-customWhite  from-navStart to-navEnd  py-8 px-4`}
        style={{ marginTop: navBarHeight + 4, backgroundColor: "#262626 " }}
      >
        {showTerms && <Terms toggleTerms={toggleTerms} />}
        {showPrivacy && <Privacy togglePrivacy={togglePrivacy} />}
        {userData && (
          <BugTab
            email={userData.email}
            name={userData.contactName}
            setEmail={handleBugEmail}
            setText={handleBugText}
            submit={(file) => handleUploadAndSendEmail(file)}
            error={bugError}
            handleFileChange={handleUpload}
          />
        )}
        <div className="w-3/12 mx-auto mt-8 mb-4">
          <img className="w-full" src={SettingsIcon} alt="Settings icon" />
        </div>
        <h1 className="font-bold text-center w-full text-customWhite md:text-xl my-4">
          Account Settings{" "}
        </h1>

        {dataFetched && (
          <div className="flex flex-col items-center w-full my-4 text-customWhite md:text-lg ">
            <button onClick={handleNotifications} className="underline my-2">
              Notification settings
            </button>
            <button onClick={handlePassword} className="underline my-2">
              Change your password
            </button>
            <button onClick={handleDataProfile} className="underline my-2">
              Data Profile
            </button>
            <button onClick={handleEditDataProfile} className="underline my-2">
              Edit your data profile
            </button>
            <button onClick={handlePauseDataProfile} className="underline my-2">
              Pause/Reactivate your match profile
            </button>
            <button onClick={toggleTerms} className="underline my-2">
              Read our Terms & Conditions
            </button>
            <button onClick={togglePrivacy} className="underline my-2">
              Privacy Policy
            </button>
            {userData.category==="startup" && <button
              onClick={toggleShare}
              className="underline my-2 text-bluePrairi"
            >
              Share <span className="font-bold">PRAIRI</span>
            </button>}
            <button onClick={()=>setLogoutPopup(true)} className="underline my-2">
            Log out
          </button>
          </div>
        )}
        <Modal open={showShare} size="full" border="blue">
          <div className="  py-4 px-4  text-silverPrairi  flex items-center flex-col">
            <div className="flex items-end justify-end pb-2 w-full">
              <TextButton
                label="x"
                color="silver"
                click={() => setShowShare(false)}
              />
            </div>

            <p className="mb-4 ">
              As we're a startup too, you can help us grow by{" "}
              <span className="font-bold">spreading the word!</span>
            </p>
            <p className="mb-5 ">
              {" "}
              Just click any of the below links and copy/paste (or change) this
              pre-written{" "}
              <span
                className="underline font-bold cursor-pointer"
                onClick={() => setShowShareMessage(true)}
              >
                message.
              </span>{" "}
              It's fast and easy! Thanks in advance! :)
            </p>
            <div className="flex justify-between w-3/5 lg:w-2/5  mx-auto ">
              <TwitterShareButton url={url} title={sharedMessage}>
                <TwitterIcon
                  round
                  size={32}
                  onClick={() => storeShare(currentUserId, "Twitter")}
                />
              </TwitterShareButton>
              <FacebookShareButton url={facebookShareUrl}>
                <FacebookIcon
                  round
                  size={32}
                  onClick={() => storeShare(currentUserId, "Facebook")}
                />
              </FacebookShareButton>
              <LinkedinShareButton
                source={sharedMessage}
                url={url}
                title={sharedMessage}
                summary={sharedMessage}
              >
                <LinkedinIcon
                  size={32}
                  round
                  onClick={() => storeShare(currentUserId, "Linkedin")}
                />
              </LinkedinShareButton>
              <WhatsappShareButton url={url} title={sharedMessage}>
                <WhatsappIcon
                  size={32}
                  round
                  onClick={() => storeShare(currentUserId, "Whatsapp")}
                />
              </WhatsappShareButton>
            </div>
            <Modal open={showShareMessage} border="no-border" size="lg">
              <div className="pb-2 px-2">
                <div className="flex justify-end ">
                  <button onClick={copyText} className="pr-5">
                    <FaCopy />
                  </button>
                  <TextButton
                    label="x"
                    color="silver"
                    click={() => setShowShareMessage(false)}
                  />
                </div>
                <div>
                  <p>
                    Hey, our startup just joined PRAIRI, where you can find the
                    right investors (without even looking for them). Check it
                    out at www.prairi.com{" "}
                  </p>
                </div>
              </div>
            </Modal>
          </div>
        </Modal>
              <Modal open={logoutPopup} size="md">
                <LogoutConfirmation
                  stay={() => setLogoutPopup(false)}
                  logout={handleLogout}
                />
              </Modal>
      </div>
    );
  }
};

export default Settings;
