import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { updateLatestActions } from "../utils";
import Logo from "../assets/unicorn-crying.png";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useMediaQuery } from "react-responsive";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  Timestamp,
  addDoc,
} from "../firebase/firebaseApp";
import { MdFreeCancellation } from "react-icons/md";

function Cancel() {
  const [openModal, setOpenModal] = useState(true);
  const [currentUserData, setCurrentUserData] = useState({});
  const functions = getFunctions();
  const sendEmailFn = httpsCallable(functions, "sendEmail");
  const [userRef, setUserRef] = useState({});
  let { docId } = useParams();
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOKButtonClick = () => {
    setOpenModal(false);
    navigate("/");
  };

  useEffect(() => {
    const getDataFromDb = async () => {
      try {
        const q = query(collection(db, "preUsers"), where("id", "==", docId));
        const querySnapshot = await getDocs(q);
        let userData;
        let userId;
        querySnapshot.forEach((doc) => {
          userData = doc.data();
          userId = doc.id;
        });
        setCurrentUserData(userData);
        return { userData, userId };
      } catch (error) {
        // Handle error
        console.log(error);
      }
    };

    const sendEmail = async () => {
      try {
        const docRef = doc(db, "preUsers", docId);
        const docSnap = await getDoc(docRef);
        await sendEmailFn({
          templateId: 49,
          recipients: [
            {
              name: "Carolina",
              email: "kro.ruz@gmail.com",
            },
            {
              name: "Peter",
              email: "peter@prairi.com",
            },
          ],
          params: {
            EMAIL: docSnap.data().email,
          },
        });

      } catch (error) {
        // Handle error
        console.log(error);
      }
    };

    getDataFromDb();
    sendEmail()
  }, [docId]);

  return (
    <div className="flex p-8 items-center justify-between text-base md:text-lg">
      <Modal
        open={openModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className="min-w-[250px]
                max-w-[300px]"
          sx={{
            background: "#1e1e1d",
            color: "#f2f2f2",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "35%",
            borderRadius: "50px",
            padding: "1%",
            opacity: "1",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
          }}
        >
          <img src={Logo} className="w-1/6 h-auto mx-auto mb-4" alt=""></img>

          <p className="mb-2 text-center">Oops!</p>
          <p className="text-center mb-2">
            There was a problem with your payment.
          </p>
          <p className="text-center mb-2">
            No worries, no charges have been made.
          </p>
          <p className="text-center mb-2">
            Please <a href="mailto:peter@prairi.com" className="text-bluePrairi underline">contact us</a> for further assistance.
          </p>
          <div className="flex justify-center ">
            <button
              className="py-1.5 px-3 text-lg mt-3 text-center rounded-lg bg-customWhite text-backgroundPrairi border-2 border-lighterGray justify-end w-1/4"
              onClick={()=>navigate("/")}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default Cancel;
