import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import validator from "validator";
import { useMediaQuery } from "react-responsive";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import LawIcon from "../assets/kassailaw.png";
import SeattleIcon from "../assets/seattle.png";
import StockIcon from "../assets/stockholm.png";
import IRPIcon from "../assets/irp.png"
import ReCAPTCHA from "react-google-recaptcha";
import { updateButtonClickCount, sendBugEmails } from "../utils";
import { BugTab } from "@peterfosso/prairi-components";
import { getFunctions, httpsCallable } from "firebase/functions";
const Partner = () => {
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [data, setData] = useState({
    email: "",
    error: "",
    loading: false,
    contactName: "",
    text: "",
    message: "",
    code: "",
    emailError: false,
    contactNameError: false,
    textError: false,
    showCaptcha: false,
    verified: false,
  });
    const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
    const [bugEmail, setBugEmail] = useState("");
    const [bugText, setBugText] = useState(null);
    const [bugError, setBugError] = useState(null);

    const handleUpload = (e) => {
      return new Promise((resolve, reject) => {
        const selectedFile = e.target.files[0];
  
        const storageRef = ref(storage, `bugs/${selectedFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);
  
        uploadTask.on(
          "state_changed",
          null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
          (error) => {
            // Manejador de error
            console.error("Upload failed:", error);
            reject(error);
          },
          async () => {
            // Manejador de éxito
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
            console.log("uploaded correctly");
            setScreenshotUrl(downloadURL);
          }
        );
      });
    };
  
    const handleBugText = (e) => {
      const value = e.target.value;
      setBugText(value);
      if (value !== "") {
        setBugError("");
      }
    };
  
    const handleBugEmail = (e) => {
      const value = e.target.value;
      setBugEmail(value);
      if (value !== "") {
        setBugError("");
      }
    };
  
    const validateFields = () => {
      if (!bugText || bugText.trim() === "") {
        setBugError("Please describe the bug.");
        return false;
      }
      if (!bugEmail || bugEmail.trim() === "") {
        setBugError("We need your email address.");
        return false;
      }
      return true;
    };
  
    const handleUploadAndSendEmail = async (file) => {
      try {
        if (!validateFields()) return;
  
        let uploadedUrl = screenshotUrl;
        if (file) {
          uploadedUrl = await handleUpload({ target: { files: [file] } });
        }
  
        console.log(`screenshotUrl ${uploadedUrl}`);
        sendBugEmails(bugEmail, "", bugText, "Landing page", uploadedUrl || "");
      } catch (error) {
        console.error("Error uploading file or sending email:", error);
      }
    };
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  const updateFieldValidity = (bool, fieldName) => {
    if (bool) {
      setData({ ...data, [`${fieldName}Error`]: true });
    } else {
      setData({ ...data, [`${fieldName}Error`]: false });
    }
  };
  const isValid = (fieldName) => {
    switch (fieldName) {
      case "email":
        updateFieldValidity(!validator.isEmail(email), fieldName);
        break;
      case "contactName":
        updateFieldValidity(validator.isEmpty(contactName), fieldName);
        break;
      case "text":
        updateFieldValidity(validator.isEmpty(text), fieldName);
        break;
      default:
        break;
    }
  };

  const verificationHandler = (value) => {
    if (value) {
      setData({ ...data, showCaptcha: false, verified: true });
      handleSubmit();
    }
  };

  const isFinallyValid = () => {
    let dataCopy = { ...data };
    if (!validator.isEmail(email)) {
      dataCopy = {
        ...dataCopy,
        emailError: true,
        error: "Fill all the required fields",
      };
    }
    if (validator.isEmpty(contactName)) {
      dataCopy = {
        ...dataCopy,
        contactNameError: true,
        error: "Fill all the required fields",
      };
    }
    if (validator.isEmpty(text)) {
      dataCopy = {
        ...dataCopy,
        textError: true,
        error: "Fill all the required fields",
      };
    }
    setData({ ...dataCopy });
    // override global variables
    const { codeError, emailError, contactNameError, textError } =
      dataCopy;
    return codeError ||
      emailError ||
      contactNameError ||
      textError
      ? false
      : true;
  };

  const handleSubmit = async () => {
    setData({
      ...data,
      error: "",
      showCaptcha: false,
      verified: true,
      loading: true,
      message: "Sending request...",
    });
    const functions = getFunctions();
    const sendEmailFn = httpsCallable(functions, "sendEmail");
    try {
      // check if email is already used
      if (!email) {
        throw new Error("Email address is required.");
      }
      //Send an email to admin about a new invitation request
      await sendEmailFn({
        templateId: 28,
        recipients: [
          {
            name: "Carolina",
            email: "kro.ruz@gmail.com",
           
          },
          {
            name: "Peter",
            email: "no-reply@prairi.com",
          },
        ],
        params: {
          NAME: contactName,
          EMAIL: email,
          MESSAGE: text,
        },
      });
      navigate("/")
    } catch (err) {
      setData({
        ...data,
        error: err.message,
        loading: false,
        message: "",
      });
    }
  };

  const showCaptchaHandler = (e) => {
    e.preventDefault();
    if (isFinallyValid()) {
      setData({ ...data, showCaptcha: true });
    }
  };

  const {
    email,
    text,
    error,
    loading,
    message,
    contactName,
    emailError,
    contactNameError,
    textError,
    showCaptcha,
    verified,
  } = data;
  return (
    <div className="w-full">
            <BugTab
              email={bugEmail}
              setEmail={handleBugEmail}
              setText={handleBugText}
              submit={(file) => handleUploadAndSendEmail(file)}
              error={bugError}
              handleFileChange={handleUpload}
            />
      <div className="w-full py-8 h-halfScreen text-textPrairi bg-lastSection bg-center bg-cover flex flex-col justify-center items-center  ">
        <h1 className="text-center uppercase text-3xl md:text-6xl my-4  font-bold ">
          P a r t n e r s
        </h1>
        <p className="italic text-center md:text-2xl">Contact us to be part of PRAIRI</p>
      </div>



      {!isMobile && <div className=" w-full bg-navStart flex items-start p-8">
        <div className=" flex flex-col mx-auto items-center justify-center px-4 pt-8 w-1/3">
          <div className="w-[80%]  rounded-md ">
            <img className="" src={LawIcon} alt="Kaissalaw" />
          </div>
          <div className="mt-24 w-[80%] ">
            <img className="" src={SeattleIcon} alt="Seattle" />
          </div>

        </div>
        <div className="bg-lighterGray shadow-black  w-11/12 md:max-w-lg rounded-xl mt-2 py-2.5 px-5 shadow-lg my-4 ">
          <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
            Contact us
          </h1>
          <h3 className="mb-2 text-center text-sm md:text-lg italic text-white">
            Let's explore what we might do together
          </h3>
          <form className=" py-0 px-5" onSubmit={showCaptchaHandler}>
            <div className="mt-5">
              <label htmlFor="contactName">Your Name</label>
              <input
                className={`${
                  contactNameError ? "border-red-500" : "border-blackish"
                } mt-2.5 w-full rounded border-2 p-2.5 text-black appearance-none outline-none`}
                type="text"
                name="contactName"
                id="contactName"
                placeholder="Your name"
                value={contactName}
                onChange={handleChange}
                onBlur={() => isValid("contactName")}
              ></input>
              {contactNameError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Contact name is required
                </div>
              )}
            </div>
            <div className="mt-5">
              <label htmlFor="email">Email</label>
              <input
                className={`${
                  emailError ? "border-red-500" : "border-blackish"
                } mt-2.5 w-full rounded border-2 p-2.5 text-black appearance-none outline-none`}
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                value={email}
                onChange={handleChange}
                onBlur={() => isValid("email")}
              ></input>
              {emailError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Provide a valid email
                </div>
              )}
            </div>

            <div className="mt-5 ">
              <label htmlFor="text">Message</label>
              <textarea
                className={`${
                  textError ? "border-red-500" : "border-blackish"
                } mt-2.5 w-full rounded border p-2.5 text-black outline-none`}
                name="text"
                id="text"
                placeholder="Your message"
                value={text}
                onChange={handleChange}
                onBlur={() => isValid("text")}
                rows="3"
              ></textarea>
              {textError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Invalid message
                </div>
              )}
            </div>
            {error && <p className="my-2 text-center text-red-500">{error}</p>}
            {message && (
              <p className="my-2 text-center text-green-500">{message}</p>
            )}
            <div className=" my-2.5 mx-0 text-center">
              <input
                type="submit"
                disabled={loading}
                className=" uppercase bg-body w-full my-2 cursor-pointer rounded border border-silverPrairi p-2.5 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                value="submit"
              ></input>
            </div>
            {showCaptcha && (
                <ReCAPTCHA
                  className="flex justify-center pt-4"
                  sitekey="6LfiNNgjAAAAAC_oJEe3mXyKzDky3ncopW49_R3p"
                  onChange={handleSubmit}
                />
              )}
          </form>
        </div>
        <div className=" flex flex-col mx-auto items-center justify-center px-4 pt-8 w-1/3">
          <div className="w-[80%] rounded-md ">
            <img className="" src={StockIcon} alt="Kaissalaw" />
          </div>
          <div className="mt-20 w-[80%] ">
                    <Link className="md:mx-2 text-bluePrairi" to="https://www.investmentreadinessprocess.com" target="_blank">
                    <img className="cursor-pointer" src={IRPIcon} alt="IRP" /></Link>
           
          </div>

        </div>
      </div>}



      {isMobile && <div className=" w-full bg-navStart flex flex-col items-center justify-center px-5">

        <div className="bg-lighterGray shadow-black items-center w-11/12 md:max-w-lg rounded-xl mt-2 py-2.5 px-5 shadow-lg my-4 justify-center">
          <h1 className="mb-4 text-center text-xl text-gray-500 uppercase">
            Contact us
          </h1>
          <h3 className="mb-2 text-center text-sm md:text-lg italic text-white">
            Let's explore what we might do together
          </h3>
          <form className=" py-0 px-5" onSubmit={showCaptchaHandler}>
            <div className="mt-5">
              <label htmlFor="contactName">Your Name</label>
              <input
                className={`${
                  contactNameError ? "border-red-500" : "border-blackish"
                } mt-2.5 w-full rounded border-2 p-2.5 text-black appearance-none outline-none`}
                type="text"
                name="contactName"
                id="contactName"
                placeholder="Your name"
                value={contactName}
                onChange={handleChange}
                onBlur={() => isValid("contactName")}
              ></input>
              {contactNameError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Contact name is required
                </div>
              )}
            </div>
            <div className="mt-5">
              <label htmlFor="email">Email</label>
              <input
                className={`${
                  emailError ? "border-red-500" : "border-blackish"
                } mt-2.5 w-full rounded border-2 p-2.5 text-black appearance-none outline-none`}
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                value={email}
                onChange={handleChange}
                onBlur={() => isValid("email")}
              ></input>
              {emailError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Provide a valid email
                </div>
              )}
            </div>

            <div className="mt-5 ">
              <label htmlFor="text">Message</label>
              <textarea
                className={`${
                  textError ? "border-red-500" : "border-blackish"
                } mt-2.5 w-full rounded border p-2.5 text-black outline-none`}
                name="text"
                id="text"
                placeholder="Your message"
                value={text}
                onChange={handleChange}
                onBlur={() => isValid("text")}
                rows="3"
              ></textarea>
              {textError && (
                <div className="w-full text-sm text-red-500 mt-2">
                  Invalid message
                </div>
              )}
            </div>
            {error && <p className="my-2 text-center text-red-500">{error}</p>}
            {message && (
              <p className="my-2 text-center text-green-500">{message}</p>
            )}
            <div className=" my-2.5 mx-0 text-center">
              <input
                type="submit"
                disabled={loading}
                className=" uppercase bg-body w-full my-2 cursor-pointer rounded border border-silverPrairi p-2.5 text-base text-white outline-none transition-all duration-300 ease-in-out hover:scale-105 "
                value="submit"
              ></input>
            </div>
            {showCaptcha && (
                <ReCAPTCHA
                  className="flex justify-center pt-4"
                  sitekey="6LfiNNgjAAAAAC_oJEe3mXyKzDky3ncopW49_R3p"
                  onChange={handleSubmit}
                />
              )}
          </form>
        </div>
        <div className=" flex flex-col w-full mx-auto items-center justify-center px-4 pt-8">
          <div className="w-full mb-8 rounded-md ">
            <img className="w-[80%]" src={LawIcon} alt="Kaissalaw" />
          </div>
          <div className="mb-8">
            <img className="w-[70%] " src={SeattleIcon} alt="Seattle" />
          </div>
          <div className="mb-8">
            <img className="w-[70%]" src={StockIcon} alt="Stockholm" />
          </div>
          <div className="mb-8">
          <Link className="md:mx-2 text-bluePrairi" to="https://www.investmentreadinessprocess.com" target="_blank">
            <img className="w-[70%]" src={IRPIcon} alt="IRP" />
            </Link>
          </div>
        </div>

      </div>}


    </div>
  );
};

export default Partner;