import React, { useState,useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import FoundersLogo from "../../assets/founders.png";
import Switch from "react-switch";
import { investorContext, authContext } from "../context/context";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "../../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions, getDataFromUser } from "../../utils";
import NextButton from "./../common/NextButton/NextButton";
const InvestorFounders = (props) => {
  const { setDataFunc, globalData } = useContext(investorContext);
  const foundersData = globalData.formData.founders;
  const { currentUser } = useContext(authContext);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [data, setData] = useState({
    founders: foundersData.founders || "",
    nonfounder: foundersData.nonfounder || "",
    diversity: foundersData.diversity || "",
    error: "",
    loading: false,
    message: "",
  });
  const [userRef, setUserRef] = useState([])
  const diversitySelectOptions = [
    {name: "Woman", value: "woman"},
    {name: "POC (person of color)", value: "POC"},
    {name: "LGBTQ+", value: "LGBTQ+"},
    {name: "Immigrant", value: "immigrant"},
    {name: "Person with disabilities", value: "person with disabilities"},
    {name: "Senior/65+ entrepreneur", value: "senior/65+"},
    {name: "Teen/19- entrepreneur", value: "teen/19-"},
  ]

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const email = currentUser?.email;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      let ref = doc(db, "users", userId)
      return { userData, userId, ref };
    } catch (error) {
      // console.log(error);
      return { userData: null };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try{
        const { ref } = await getDataFromDb()
        setUserRef(ref)
      } catch{
        console.log(error)
      }
    }
fetchData()
  }, [currentUser])

  

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);
  let navigate = useNavigate();
  const foundersNumberSelectOptions = ["1", " 2", "3+", "No preference"];
  const nonFoundersNumberSelectOptions = ["0", "1", " 2", "3+", "No preference"];
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const time = Timestamp.fromDate(new Date())
    const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });

      setData({
        ...data,
        error: "",
        
        loading: true,
        message: "Saving... (click progress bar if you wish to edit)",
      });

      setTimeout(() => {
        setDataFunc({
          ...globalData,
          formData: {
            ...globalData.formData,
            founders: {
              ...globalData.formData.founders,
              founders,
              nonfounder,
              diversity,
              completed: true,
            },
          },
        });
        updateLatestActions(userRef, "Saved Founders form", isMobile);
      }, 2000);
    
  };


  const handleSubmitAndNext = (e) => {
    handleSubmit(e);
    navigate("/investorregistration/ownership");
  };

  const handleSubmitAndFinish = (e) => {
    handleSubmit(e);
    navigate("/investorregistration/matching");
  };


  const {
    founders,
    diversity,
    error,
    message,
    //loading,
    nonfounder,

  } = data;
  return (
    <div className="shadow-grey w-[90%]  rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      <div className="flex items-end pl-3 md:pl-0">
        <div
          className={` h-10 md:h-16 flex items-center justify-center mr-2 md:mr-4 `}
        >
          <img className=" h-full  " src={FoundersLogo} alt="Company" />
        </div>
        <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
          Founders & Team
        </h1>
      </div>
      <form className=" " onSubmit={handleSubmitAndNext}>
        <div className="mt-5 px-3 md:pl-24">
          <h1>Preferred number of founders</h1>
          <div className="flex mt-2 items-center bg-body py-2 pl-2">
            {foundersNumberSelectOptions.map((numberFounders, index) => {
              return (
                <input
                  className={` mr-2 ${
                    numberFounders === founders
                      ? "border-cyan-600"
                      : "border-gray-500"
                  }  cursor-pointer ${
                    numberFounders === "No preference" ? "w-32" : "w-12"
                  }  h-8 border-2 rounded-full   text-white outline-none `}
                  key={"key" + index}
                  type="button"
                  value={numberFounders}
                  name="founders"
                  onClick={handleChange}
                ></input>
              );
            })}
          </div>
        </div>
        <div className="mt-5 px-3 md:pl-24">
          <h1>Preferred number of Non-Founder Team members </h1>
          <div className="flex mt-2 items-center bg-body py-2 pl-2">
            {nonFoundersNumberSelectOptions.map((numberNonFounder, index) => {
              return (
                <input
                  className={` mr-2 ${
                    numberNonFounder === nonfounder
                      ? "border-cyan-600"
                      : "border-gray-500"
                  }  cursor-pointer  ${
                    numberNonFounder === "No preference" ? "w-32" : "w-12"
                  } h-8 border-2 rounded-full   text-white outline-none `}
                  key={"key" + index}
                  type="button"
                  value={numberNonFounder}
                  name="nonfounder"
                  onClick={handleChange}
                ></input>
              );
            })}
          </div>
        </div>
        <div className="my-4 w-[90%]">
          <h1 className="  pl-3 md:pl-24  text-white ">
          Since some investors prefer companies with one or more Founders 
          represented in the following, feel free to select any that may apply:
          </h1>
          {diversitySelectOptions.map((selection) => {
            return (
              <div
                key={selection.value}
                className="mt-3 px-3 md:pl-24 flex items-center "
              >
                <Switch
                  onChange={() =>
                    setData({
                      ...data,
                      diversity: {
                        ...diversity,
                        [selection.value]: !diversity[selection.value],
                      },
                    })
                  }
                  checked={diversity[selection.value]}
                  id={selection.value}
                />
                <label htmlFor={selection.value} className="ml-5">
                  {selection.name}
                </label>
              </div>
            );
          })}
        </div>

        {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
        {message && (
          <p className="my-2 px-3 md:pl-24 text-green-500">Saving... <span className='text-silverPrairi'>(click progress bar if you wish to edit)</span></p>
        )}
          <div className="relative flex justify-center my-5 items-end px-3 md:pl-24">
            <div
              className="absolute right-0 bottom-6 text-silverPrairi underline cursor-pointer"
              onClick={(e) => handleSubmitAndFinish(e)}
            >
              Save & Finish
            </div>
            <NextButton value="SAVE" />
          </div>
      </form>
    </div>
  );
};

export default InvestorFounders;
