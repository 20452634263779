import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  db,
  getDoc,
  doc,
  Timestamp,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "../../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
import { updateLatestActions, getDataFromUser } from "../../utils";
import { dataContext, authContext } from "../context/context";
import OwnershipLogo from "../../assets/ownership.png";
import TractionLogo from "../../assets/traction.png";
import NextButton from "./../common/NextButton/NextButton";
import Slider from "rc-slider";
import CurrencyFormat from "react-currency-format";
import "rc-slider/assets/index.css";
const Ownership = (props) => {
  const { currentUser } = useContext(authContext);
  const [userRef, setUserRef] = useState([])
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { setDataFunc, globalData } = useContext(dataContext);
  const ownershipData = globalData.formData.ownership;
  const [data, setData] = useState({
    capitalization: ownershipData.capitalization || "",
    traction: ownershipData.traction || "",
    expectedtraction: ownershipData.expectedtraction || "",
    impressive: ownershipData.impressive || "",
    revenue: ownershipData.revenue || "",
    error: "",
    loading: false,
    message: "",
  });

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const email = currentUser?.email;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      let ref = doc(db, "users", userId)
      return { userData, userId, ref };
    } catch (error) {
      // console.log(error);
      return { userData: null };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try{
        const { ref } = await getDataFromDb()
        setUserRef(ref)
      } catch{
        console.log(error)
      }
    }
fetchData()
  }, [currentUser])


  const currency = globalData.formData.industry.currency;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let navigate = useNavigate();
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const time = Timestamp.fromDate(new Date())
    const latestTime = time.toDate().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
    if (!traction || !impressive) {
      setData({ ...data, error: "Fill all the required fields." });
    } else {
      setData({
        ...data,
        error: "",
        loading: true,
        message: "Saving... (click progress bar if you wish to edit)",
      });

      setTimeout(() => {
        setDataFunc({
          ...globalData,
          formData: {
            ...globalData.formData,
            ownership: {
              ...globalData.formData.ownership,
              capitalization,
              traction,
              expectedtraction,
              impressive,
              revenue,
              completed: true,
            },
          },
        });
        navigate("/startupregistration/support");
        updateLatestActions(userRef, "Saved ownership form", isMobile)
      }, 2000);
    }
  };

  const {
    capitalization,
    traction,
    expectedtraction,
    impressive,
    revenue,
    error,
    message,
    // loading,
  } = data;


  useEffect(() => {
    if (traction!=="" && impressive!==""){
      setData(prevData => ({
        ...prevData, error: false
      }))
    }
  }, [traction, impressive])


  const OwnershipSelectOptions = [
    { name: "Choose from the following", value: "" },
    { name: "One founder owns 100%", value: "One founder owns 100%" },
    { name: "2 founders own shares", value: "2 founders own shares" },
    { name: "3 or more founders own shares", value: "3 or more founders own shares" },
    { name: "Founders own shares and investors own shares", value: "Founders own shares and investors own shares" },
  ];

  return (
    <div className="shadow-grey w-[90%]   rounded-md mt-2 md:max-w-xl mx-auto py-2.5   ">
      <div className="flex items-end pl-3 md:pl-0">
        <div
          className={` h-10 md:h-10 flex items-center justify-center mr-2 md:mr-4 `}
        >
          <img className=" h-full  " src={OwnershipLogo} alt="Company" />
        </div>
        <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
          Ownership
        </h1>
      </div>
      <form className=" " onSubmit={handleSubmit}>
        <div className="mt-5 px-3 md:pl-24">
          <label htmlFor="capitalization">Capitalization [optional]</label>
          <select
            className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
            name="capitalization"
            id="capitalization"
            value={capitalization}
            onChange={handleChange}
          >
            {OwnershipSelectOptions.map((option, index) => {
              return (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={index === 0 ? true : false}
                >
                  {option.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex items-end pl-3 md:pl-0 mt-5">
          <div
            className={` h-10 md:h-10 flex items-center justify-center mr-2 md:mr-4 `}
          >
            <img className=" h-full  " src={TractionLogo} alt="Company" />
          </div>
          <h1 className=" text-base  md:text-xl text-white uppercase font-bold">
            Traction
          </h1>
        </div>
        <div className="mt-5 px-3 md:pl-24">
          <select
            className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
            name="traction"
            id="traction"
            value={traction}
            onChange={handleChange}
          >
            {" "}
            <option disabled value="">
              Select from the following
            </option>
            <option value="No users/clients yet">No users/clients yet</option>
            <option value="Users/Clients but no revenue">
              Users/Clients but no revenue
            </option>
            <option value="Revenue exists">Revenue exists</option>
          </select>
        </div>
        {(traction === "No users/clients yet" ||
          traction === "Users/Clients but no revenue") && (
          <div className="mt-5 px-3 md:pl-24">
            <label htmlFor="expectedtraction">Revenue Expectations</label>
            <select
              className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
              name="expectedtraction"
              id="expectedtraction"
              value={expectedtraction}
              onChange={handleChange}
            >
              {" "}
              <option disabled value="">
                Select status of revenue expectations
              </option>
              <option value="less than 3 months">Less than 3 Months</option>
              <option value="3-6 months">3-6 Months </option>
              <option value="6-12 months">6-12 Months</option>
              <option value="1-2 years">1-2 Years</option>
              <option value="over 2 years">Over 2 Years</option>
            </select>
          </div>
        )}
        {traction === "Revenue exists" && (
          <div className="my-5 ">
            <div className="">
              <div className="mt-5 px-3 md:pl-24">
                <h1 className="my-5">
                  Company's current annual revenue [optional]
                </h1>
              </div>
              <div className="mt-5 px-3 md:pl-24">
                <div className="flex items-center">
                  <div className="w-9/12  ml-2">
                    {currency === "SEK" && (
                      <Slider
                        step={10000}
                        min={0}
                        max={50000000}
                        allowCross={false}
                        defaultValue={0}
                        value={revenue}
                        onChange={(revenue) => setData({ ...data, revenue })}
                        trackStyle={[
                          { backgroundColor: "#00b0f0", height: "6px" },
                        ]}
                        railStyle={{ backgroundColor: "grey", height: "6px" }}
                        handleStyle={[
                          {
                            height: "15px",
                            width: "15px",
                            borderColor: "grey",
                            boxShadow: "none",
                            borderWidth: "0px",
                            borderRadius: "2px",
                            backgroundColor: "grey",
                            outline: "none",
                            opacity: 1,
                          },
                        ]}
                      />
                    )}
                    {currency !== "SEK" && (
                      <Slider
                        step={1000}
                        min={0}
                        max={5000000}
                        allowCross={false}
                        defaultValue={0}
                        value={revenue}
                        onChange={(revenue) => setData({ ...data, revenue })}
                        trackStyle={[
                          { backgroundColor: "#00b0f0", height: "6px" },
                        ]}
                        railStyle={{ backgroundColor: "grey", height: "6px" }}
                        handleStyle={[
                          {
                            height: "15px",
                            width: "15px",
                            borderColor: "grey",
                            boxShadow: "none",
                            borderWidth: "0px",
                            borderRadius: "2px",
                            backgroundColor: "grey",
                            outline: "none",
                            opacity: 1,
                          },
                        ]}
                      />
                    )}
                  </div>
                  <div className="ml-4 font-bold flex items-center">
                    <CurrencyFormat
                      className="ml-2"
                      displayType="text"
                      thousandSeparator={true}
                      value={revenue}
                    />
                    {((revenue === 50000000 && currency === "SEK") ||
                      (revenue === 5000000 && currency !== "SEK")) && (
                      <span className="ml-0">+</span>
                    )}
                                        {((revenue === 100000000 && currency === "SEK") ||
                      (revenue === 10000000 && currency !== "SEK")) && (
                      <span className="ml-0">+</span>
                    )}
                    <span className="ml-2 ">{currency.toUpperCase()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="mt-5 px-3 md:pl-24">
          <label>
            What might impress an investor the most about your company? 
          </label>
          <textarea
            className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none resize-y min-h-20 max-h-64"
            type="text"
            name="impressive"
            id="impressive"
            placeholder="What might impress an investor?"
            value={impressive}
            onChange={handleChange}
          ></textarea>
        </div>
        {error && <p className="my-2 px-3 md:pl-24 text-red-500">{error}</p>}
        {message && (
          <p className="my-2 px-3 md:pl-24 text-green-500">
            Saving...{" "}
            <span className="text-silverPrairi">
              (click progress bar if you wish to edit)
            </span>
          </p>
        )}
        <div className="flex justify-center my-5 px-3 md:pl-20">
          <NextButton value="SAVE" />
        </div>
      </form>
    </div>
  );
};

export default Ownership;
