import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import { usStates, countryRegions } from "../countries.js";
import Terms from "./common/Terms.js";
import countryList from "react-select-country-list";
import {
  industrySelectOptions,
  stageSelectOptions,
  modelSelectOptions,
  productTypeSelectOptions,
} from "../options.js";
import axios from "axios";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import CurrencyFormat from "react-currency-format";
import {
  BugTab,
  Modal,
  PrairiButton,
  SaveReminder,
  PreferencesSelector,
  TextButton,
} from "@peterfosso/prairi-components";
import { navBarContext, authContext } from "./context/context";
import { useMediaQuery } from "react-responsive";
import {
  query,
  collection,
  db,
  where,
  updateDoc,
  doc,
  getDocs,
} from "../firebase/firebaseApp";
import {
  sendBugEmails,
  getDataFromUser,
  createBooleanObject,
  updateLatestActions
} from "../utils.js";
import Industries from "../assets/industry.png";
import Model from "../assets/biz_model.gif";
import Stage from "../assets/stage.gif";
import Investment from "../assets/amount.gif";
import Location from "../assets/companyLocation.png";


const ExpressSignUp = () => {
  const { navBarHeight } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const didMount = useRef(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let navigate = useNavigate();
  let location = useLocation();

  const options = {
    industries: industrySelectOptions,
    stages: stageSelectOptions,
    models: modelSelectOptions,
    types: productTypeSelectOptions,
  };

  const countryOptions = useMemo(() => countryList().getLabels(), []);
  const countriesRegion = [
    "Select countries / regions",
    "ANYWHERE",
    "AFRICA",
    "ASIA",
    "CENTRAL AMERICA",
    "EUROPE",
    "NORDICS",
    "MIDDLE EAST",
    "NORTH AMERICA",
    "SOUTH AMERICA",
    "OCEANIA",
    ...countryOptions,
  ];
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [savePopup, setSavePopup] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState("");
  const [bugError, setBugError] = useState(null);
  const [currency, setCurrency] = useState("EUR");
  const [isAmplified, setIsAmplified] = useState(false);
  const [amount, setAmount] = useState([3000000, 6000000]);
  const [dataFetched, setDataFetched] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showIndustrySelector, setShowIndustrySelector] = useState(false);
  const [showModelSelector, setShowModelSelector] = useState(false);
  const [showFocusSelector, setShowFocusSelector] = useState(false);
  const [showStagesSelector, setShowStagesSelector] = useState(false);
  
  const [showCountrySelector, setShowCountrySelector] = useState(false);
  const [countriesError, setCountriesError] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [selectedPreferences, setSelectedPreferences] = useState({
    industries: options.industries,
    stages: options.stages,
    models: options.models,
    types: options.types,
  });
  const [data, setData] = useState({
    currentLocation: "",
    selectedStartupCountry: "Select countries / regions",
    formData: {
      company: {
        company: "",
        country: "",
        //state: "",
        startupcountries: ["ANYWHERE"],
        startupstates: usStates,
        investments: "",
        investmentGroups: "",
        majority: {
          "Equity investments (cash for ownership)": true,
          "Revenue-based financing": false,
          "Services in exchange for equity": false,
        },
        completed: true,
        contactName: "",
        startupAllocates: false,
        photo: "",
      },
      industry: {
        industry: {
          "AdTech/Media": true,
          "Big Data": true,
          "Consumer Goods": true,
          Cybersecurity: true,
          "Deep Tech (AI, Web3, Blockchain, etc.)": true,
          eCommerce: true,
          "Emerging Tech (NFTs, VR, Robotics)": true,
          EdTech: true,
          "Entertainment/Music/Gaming": true,
          Fashion: true,
          FoodTech: true,
          "Fintech/Finance": true,
          "Greentech/Cleantech/Energy": true,
          "HealthTech/MedTech": true,
          "IT/Software/SaaS": true,
          PropTech: true,
          Retail: true,
          "Service Industry Solutions": true,
          "Space Technology": true,
          "Transport and Logistics (Shared Mobility/Maritime/Delivery Services)": true,
        },
        model: {
          B2C: true,
          B2B: true,
        },
        stage: {
          early: true,
          growth: true,
          series: true,
          higher: true,
        },
        type: {
          Hardware: true,
          Software: true,
          SaaS: true,
          Other: true,
        },
        currency: "EUR",
        amount: [3000000, 6000000],
        completed: true,
      },

      founders: {
        founders: "",
        nonfounder: "",
        diversity: {
          woman: false,
          POC: false,
          "LGBTQ+": false,
          immigrant: false,
          "person with disabilities": false,
          "senior/65+": false,
          "teen/19-": false,
        },
        completed: true,
      },
      ownership: {
        capitalization: {
          single: false,
          double: false,
          triple: false,
          team: false,
          mixed: false,
        },
        traction: {
          users: false,
          norevenue: false,
          revenue: 0,
        },
        expectation: {
          three: false,
          six: false,
          twelve: false,
          year: false,
          long: false,
        },
        minimum: null,
        completed: true,
      },
      support: {
        support: "nomatter",
        expertise: false,
        contacts: false,
        other: false,
        different: "",
        remind: "six",
        completed: true,
      },
      matching: {
        completed: true,
      },
    },
    showTerms: false
  });
  const currencySelectOptions = ["EUR", "USD", "GBP", "SEK"];

  const toggleAmplify = () => {
    setData((prevData) => {
      const newAmount = isAmplified
        ? [
            Math.ceil(prevData.formData.industry.amount[0] / 10),
            Math.ceil(prevData.formData.industry.amount[1] / 10),
          ]
        : [
            Math.ceil(prevData.formData.industry.amount[0] * 10),
            Math.ceil(prevData.formData.industry.amount[1] * 10),
          ];
  
      // También actualizamos el estado `amount`
      setAmount(newAmount);
  
      return {
        ...prevData,
        formData: {
          ...prevData.formData,
          industry: {
            ...prevData.formData.industry,
            amount: newAmount,
          },
        },
      };
    });
  
    setIsAmplified((prev) => !prev);
  };
  


  const toggleTerms = () => {
    window.scrollTo(0,0)
    setData({ ...data, showTerms: !data.showTerms });
  };

  const handleStartupCountryChange = (selectedCountry) => {
    if (selectedCountry) {
      const selectedCountriesArray = data.formData.company.startupcountries;
      let filteredArray = selectedCountriesArray.filter(
        (countryName) => countryName !== selectedCountry
      );
      if (countriesRegion.slice(2, 11).includes(selectedCountry)) {
        filteredArray = [
          ...filteredArray,
          ...countryRegions[selectedCountry],
          selectedCountry,
        ];
        let set = new Set(filteredArray);
        filteredArray = Array.from(set);
      } else {
        filteredArray.push(selectedCountry);
        let set = new Set(filteredArray);
        filteredArray = Array.from(set);
      }
      setData({
        ...data,
        formData: {
          ...data.formData,
          company: {
            ...data.formData.company,
            startupcountries: [...filteredArray],
          },
        },
        selectedStartupCountry: selectedCountry,
      });
    }
  };

  const removeCountryHandler = (countryName) => {
    let filteredArray = [...data.formData.company.startupcountries];
    let regionsForCountry = [];

    if (Object.keys(countryRegions).includes(countryName)) {
      filteredArray = filteredArray.filter(
        (country) => !countryRegions[countryName].includes(country)
      );
      // console.log("Delete region and its countries");
      filteredArray = filteredArray.filter(
        (country) => country !== countryName
      );
    } else {
      for (const region in countryRegions) {
        if (countryRegions[region].includes(countryName)) {
          regionsForCountry.push(region);
          // console.log("Region from country: " + region);
        }
      }

      for (const regionToRemove of regionsForCountry) {
        let counter = 0;
        for (const otherCountry of countryRegions[regionToRemove]) {
          if (filteredArray.includes(otherCountry)) {
            counter++;
            // console.log(counter);
          }
        }
        if (counter === 1) {
          // Remove the region if no other country from that region exists
          // console.log(counter);
          filteredArray = filteredArray.filter(
            (country) => country !== regionToRemove
          );
        }
      }

      filteredArray = filteredArray.filter(
        (country) => country !== countryName
      );
    }
    setData({
      ...data,
      formData: {
        ...data.formData,
        company: {
          ...data.formData.company,
          startupcountries: [...filteredArray],
        },
      },
    });
  };

  const rates = {
    USD: {
      EUR: 0.95,
      SEK: 10.91,
      GBP: 0.78,
    },
    EUR: {
      USD: 1.05,
      SEK: 11.46,
      GBP: 0.82,
    },
    SEK: {
      USD: 0.09,
      EUR: 0.09,
      GBP: 0.07,
    },
    GBP: {
      USD: 1.26,
      EUR: 1.2,
      SEK: 13.82,
    },
  };

  const convertCurrency = (amount, fromCurrency, toCurrency) => {
    if (fromCurrency === toCurrency) {
      return amount;
    }

    const rate = rates[fromCurrency][toCurrency];
    return [Math.ceil(amount[0] * rate), Math.ceil(amount[1] * rate)];
  };

  useEffect(() => {
    let newAmount = convertCurrency(
      data.formData.industry.amount,
      data.formData.industry.currency,
      currency
    );
    if (data.currency === "SEK" && !isAmplified) {
      if (newAmount[0] < 1000000) {
        newAmount[0] = 1000000;
      }
      if (newAmount[1] > 100000000) {
        newAmount[1] = 100000000;
      }
    } else {
      if (newAmount[0] < 100000 && !isAmplified) {
        newAmount[0] = 100000;
      }
      if (newAmount[1] > 10000000 && !isAmplified) {
        newAmount[1] = 10000000;
      }
    }
    setAmount(newAmount);
    setData((prevData) => ({
      ...prevData,
      formData: {
        ...prevData.formData,
        industry: {
          ...prevData.formData.industry,
          currency: currency,
          amount: newAmount,
        },
      },
    }));
  }, [currency]);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      formData: {
        ...prevData.formData,
        industry: {
          ...prevData.formData.industry,
          amount: amount,
        },
      },
    }));
  }, [amount]);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      formData: {
        ...prevData.formData,
        industry: {
          ...prevData.formData.industry,
          industry: createBooleanObject(
            selectedPreferences.industries,
            options.industries
          ),
          model: createBooleanObject(
            selectedPreferences.models,
            options.models
          ),
          stage: createBooleanObject(
            selectedPreferences.stages,
            options.stages
          ),
          type: createBooleanObject(selectedPreferences.types, options.types),
        },
      },
    }));
  }, [selectedPreferences]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!currentUser) {
      return;
    }

    const fetchData = async () => {
      const { userData, userId } = await getDataFromUser(currentUser.uid);
      setUserData(userData);
      setUserId(userId);
      setDataFetched(true);

      // Usamos la versión funcional de setData para asegurarnos de trabajar con el estado más reciente
      if (userData.formData){
        setData((prevData => ({
            ...prevData,
            formData: userData.formData
        })))
      }
      else{
        setData((prevData) => ({
          ...prevData,
          formData: {
            ...prevData.formData,
            company: {
              ...prevData.formData.company,
              company: userData.companyName?userData.companyName:userData.username,
              country: userData.country || "",
              contactName: userData.username?userData.username:userData.companyName?userData.companyName:"",
              investments: userData.previous || "",
              investmentGroups: userData.groups || "",
              startupAllocates: userData.startupPays === true,
          
            },
          },
        }));
      }

    };

    fetchData();
  }, [currentUser, dataFetched]);

  const handleSelect = (category, value, isChecked) => {
    setSelectedPreferences((prev) => ({
      ...prev,
      [category]: isChecked
        ? [...prev[category], value]
        : prev[category].filter((item) => item !== value),
    }));
  };

  const selectAll = (category) => {
    setSelectedPreferences((prev) => ({
      ...prev,
      [category]:
        prev[category].length === options[category].length
          ? []
          : [...options[category]],
    }));
  };

  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    return true;
  };

  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(
        userData.email,
        userData.contactName,
        bugText,
        "Investor express",
        uploadedUrl || ""
      );
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

const handleSubmit = async (e) => {
  e.preventDefault();
  if (!agreeToTerms) {
    alert("You must agree to the terms and conditions to continue.");
    return;
  }
  try {
    const userRef = doc(db, "users", userId);
    setLoading(true)
      await updateDoc(userRef, {
        formData: data.formData
      })
          await updateLatestActions(
            userRef,
            "Saved Express SignUp",
            isMobile
          );
      if (data.formData.company.country==="United States"){
        navigate("/bestresults")
      }
      else{
        navigate("/investorwelcome")
      }
      
    
  } catch (error) {
    console.error("erros submitting form", error)
  }
}
  
  return (
    <div
      className="w-full px-0.5 bg-rainbowBg mb-8"
      style={{ paddingTop: navBarHeight }}
    >
      <BugTab
        email={bugEmail}
        setEmail={handleBugEmail}
        setText={handleBugText}
        submit={(file) => handleUploadAndSendEmail(file)}
        error={bugError}
        handleFileChange={handleUpload}
      />

      {
        <div
          className={` w-full bg-body flex flex-col items-center justify-center pt-2 pb-24`}
        >
          <h1 className="my-2 md:my-6 text-center  text-base text-xl  tracking-wide w-full text-gray-500 uppercase">
            Your Match Preferences
          </h1>
          <div className="text-lg my-4 flex items-center justify-center text-left mx-2">Now, in order to be matched, please set your preferences.</div>
          <div className="flex flex-col w-full justify-center items-center">
            <div className="flex flex-col w-full justify-center items-center">
              {/* INDUSTRIES */}
              <div
                className={`flex items-center ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Industries}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Industries icon"
                />
                <div className="flex flex-col w-full">
                  <div className="font-bold text-xl mb-2">INDUSTRIES</div>
                  <div className="flex items-center justify-start">
                    {selectedPreferences.industries.length ===
                      industrySelectOptions.length && (
                      <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                    )}
                    {selectedPreferences.industries.length ===
                      industrySelectOptions.length && (
                      <div className="mx-4">Any industry</div>
                    )}
                    {selectedPreferences.industries.length !==
                      industrySelectOptions.length && (
                      <div className="mx-4 text-xs italic ">
                        {selectedPreferences.industries.join(", ")}
                      </div>
                    )}
                    <div
                      className="ml-2 text-silverPrairi underline cursor-pointer"
                      onClick={() => setShowIndustrySelector(true)}
                    >
                      Change
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`flex items-start ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Model}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Industries icon"
                />
                <div>
                  <div className="flex flex-col w-full mb-8">
                    <div className="font-bold text-xl mb-2">
                      BUSINESS MODEL PREFERENCE
                    </div>
                    <div className="flex items-center justify-start">
                      {selectedPreferences.models.length ===
                        modelSelectOptions.length && (
                        <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                      )}
                      {selectedPreferences.models.length ===
                        modelSelectOptions.length && (
                        <div className="mx-4">Any (B2B or B2C)</div>
                      )}
                      {selectedPreferences.models.length !==
                        modelSelectOptions.length && (
                        <div className="mx-4 text-xs italic ">
                          {selectedPreferences.models.join(", ")}
                        </div>
                      )}
                      <div
                        className="ml-2 text-silverPrairi underline cursor-pointer"
                        onClick={() => setShowModelSelector(true)}
                      >
                        Change
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full mt-4">
                    <div className="font-bold text-xl mb-2">
                      PRODUCT FOCUS PREFERENCE
                    </div>
                    <div className="flex items-center justify-start">
                      {selectedPreferences.types.length ===
                        productTypeSelectOptions.length && (
                        <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                      )}
                      {selectedPreferences.types.length ===
                        productTypeSelectOptions.length && (
                        <div className="mx-4">Any </div>
                      )}
                      {selectedPreferences.types.length !==
                        productTypeSelectOptions.length && (
                        <div className="mx-4 text-xs italic ">
                          {selectedPreferences.types.join(", ")}
                        </div>
                      )}
                      <div
                        className="ml-2 text-silverPrairi underline cursor-pointer"
                        onClick={() => setShowFocusSelector(true)}
                      >
                        Change
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* STAGES */}
              <div
                className={`flex items-center ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Stage}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Stages icon"
                />
                <div className="flex flex-col w-full">
                  <div className="font-bold text-xl mb-2">STAGES</div>
                  <div className="flex items-center justify-start">
                    {selectedPreferences.stages.length ===
                      stageSelectOptions.length && (
                      <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                    )}
                    {selectedPreferences.stages.length ===
                      stageSelectOptions.length && (
                      <div className="mx-4">Any stage</div>
                    )}
                    {selectedPreferences.stages.length !==
                      stageSelectOptions.length && (
                      <div className="mx-4 text-xs italic ">
                        {selectedPreferences.stages.join(", ")}
                      </div>
                    )}
                    <div
                      className="ml-2 text-silverPrairi underline cursor-pointer"
                      onClick={() => setShowStagesSelector(true)}
                    >
                      Change
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`flex items-start ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Investment}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Stages icon"
                />
                <div className="flex flex-col w-full">
                  <div className="font-bold text-xl mb-2">INVESTMENT RANGE</div>
                  <div className="flex items-start flex-col">
                    <div className="inline-flex items-center bg-buttonParent mb-4 py-1 pl-2">
                      {currencySelectOptions.map((currentCurrency, index) => (
                        <input
                          key={index}
                          className={`uppercase mr-1 ${
                            currentCurrency === currency
                              ? "bg-[#303030] opacity-100 border border-[#00b0f0]"
                              : "bg-inactiveButton opacity-50"
                          } text-xs cursor-pointer rounded border-solid py-1 px-4 text-white outline-none`}
                          type="button"
                          value={currentCurrency}
                          name="currency"
                          onClick={() => setCurrency(currentCurrency)}
                        />
                      ))}
                    </div>

                    <div className="flex w-[95%] items-center">
                      {currency !== "SEK" && (
                        <div className="flex w-full items-center">
                          <Slider
                            range
                            step={isAmplified ? 1000000 : 100000}
                            min={isAmplified ? 1000000 : 100000}
                            max={isAmplified ? 100000000 : 10000000}
                            allowCross={false}
                            value={amount} // Vinculamos el slider al estado
                            onChange={(values) => setAmount(values)} // Actualizamos el estado al cambiar el rango
                            trackStyle={[
                              { backgroundColor: "#00b0f0", height: "6px" },
                            ]}
                            railStyle={{
                              backgroundColor: "grey",
                              height: "6px",
                            }}
                            handleStyle={[
                              {
                                height: "15px",
                                width: "15px",
                                borderColor: "grey",
                                boxShadow: "none",
                                borderWidth: "0px",
                                borderRadius: "2px",
                                backgroundColor: "grey",
                                outline: "none",
                                opacity: 1,
                              },
                              {
                                height: "15px",
                                width: "15px",
                                borderColor: "grey",
                                borderWidth: "0px",
                                borderRadius: "2px",
                                boxShadow: "none",
                                backgroundColor: "grey",
                                outline: "none",
                                opacity: 1,
                              },
                            ]}
                          />
                          <button
                            type="button"
                            onClick={toggleAmplify}
                            className={`text-xs p-1 ml-4 border rounded-md bg-[#303030] ${
                              isAmplified
                                ? "border-bluePrairi border-solid"
                                : "border-[#303030]"
                            }`}
                          >
                            +10x
                          </button>
                        </div>
                      )}
                      {currency === "SEK" && (
                        <div className="flex w-full items-center">
                          <Slider
                            range
                            step={isAmplified ? 1000000 : 100000}
                            min={isAmplified ? 10000000 : 1000000}
                            max={isAmplified ? 1000000000 : 100000000}
                            allowCross={false}
                            value={amount} // Vinculamos el slider al estado
                            onChange={(values) => setAmount(values)} // Actualizamos el estado al cambiar el rango
                            trackStyle={[
                              { backgroundColor: "#00b0f0", height: "6px" },
                            ]}
                            railStyle={{
                              backgroundColor: "grey",
                              height: "6px",
                            }}
                            handleStyle={[
                              {
                                height: "15px",
                                width: "15px",
                                borderColor: "grey",
                                boxShadow: "none",
                                borderWidth: "0px",
                                borderRadius: "2px",
                                backgroundColor: "grey",
                                outline: "none",
                                opacity: 1,
                              },
                              {
                                height: "15px",
                                width: "15px",
                                borderColor: "grey",
                                borderWidth: "0px",
                                borderRadius: "2px",
                                boxShadow: "none",
                                backgroundColor: "grey",
                                outline: "none",
                                opacity: 1,
                              },
                            ]}
                          />
                          <button
                            type="button"
                            onClick={toggleAmplify}
                            className={`text-xs p-1 ml-4 border rounded-md bg-[#303030] ${
                              isAmplified
                                ? "border-bluePrairi border-solid"
                                : "border-[#303030]"
                            }`}
                          >
                            +10x
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="ml-2 text-sm md:text-base font-bold flex items-center">
                      Range:{" "}
                      <CurrencyFormat
                        className="mx-1.5"
                        displayType="text"
                        thousandSeparator={true}
                        value={amount[0]}
                      />{" "}
                      -{" "}
                      <CurrencyFormat
                        className="ml-1.5"
                        displayType="text"
                        thousandSeparator={true}
                        value={amount[1]}
                      />
                      <span className="ml-1.5 ">{currency.toUpperCase()}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`flex items-start ${
                  isMobile ? "w-full" : "w-1/3"
                } my-4 px-2`}
              >
                <img
                  src={Location}
                  className="w-[50px] h-[50px] mr-5"
                  alt="Stages icon"
                />
                <div className="flex flex-col w-full">
                  <div className="font-bold text-xl mb-2">LOCATION</div>
                  <div className="font-bold mb-2 text-sm">
                    Where would you prefer the startups to be located?
                  </div>
                  <div className="flex items-center justify-start">
                    {data.formData.company.startupcountries.includes(
                      "ANYWHERE"
                    ) &&
                      data.formData.company.startupcountries.length === 1 && (
                        <div className="bg-greenPrairi w-[15px] h-[15px] rounded-full" />
                      )}
                    {data.formData.company.startupcountries.includes(
                      "ANYWHERE"
                    ) &&
                      data.formData.company.startupcountries.length === 1 && (
                        <div className="mx-4">Anywhere in the world</div>
                      )}
                    {
                      data.formData.company.startupcountries.length > 0 && (
                        <div className="mx-4 text-xs italic ">
                          {data.formData.company.startupcountries
                            .filter(
                              (country) => country !== country.toUpperCase()
                            ) // Filtra los que están en mayúsculas
                            .join(", ")}
                        </div>
                      )}
                    <div
                      className="ml-2 text-silverPrairi underline cursor-pointer"
                      onClick={() => setShowCountrySelector(true)}
                    >
                      Change
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form>
          <div className=" my-4 px-3  flex flex-col  justify-between items-center text-sm ">
          <h1 className="my-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
                checked={agreeToTerms}
                required
                onChange={() => setAgreeToTerms(!agreeToTerms)}
              />
              <span className="ml-2">
                I agree to the{" "}
                <button
                  type="button"
                  required
                  onClick={() => {
                    toggleTerms();
                  }}
                  className="text-blue-500 underline underline-offset-4 cursor-pointer"
                >
                  Terms of Use
                </button>
              </span>
            </label>
          </h1>

        </div>
        </form>
          <div className="py-5">
            <PrairiButton
              label="OK, looks good!"
              border="thinGreen"
              size="md"
              rounded="soft"
              click={handleSubmit}
            />
          </div>
          {loading && <div className="text-silverPrairi italic text-sm">Loading...</div>}
        </div>
      }

      <PreferencesSelector
        open={showIndustrySelector}
        options={options.industries}
        selectedOptions={selectedPreferences.industries}
        onClose={() => setShowIndustrySelector(false)}
        error={selectedPreferences.industries.length < 1}
        title="Industries"
        icon={Industries}
        handleSelect={(value, isChecked) =>
          handleSelect("industries", value, isChecked)
        }
        selectAll={() => selectAll("industries")}
        handleSave={() => setShowIndustrySelector(false)}
      />

      <PreferencesSelector
        open={showModelSelector}
        options={options.models}
        selectedOptions={selectedPreferences.models}
        onClose={() => setShowModelSelector(false)}
        error={selectedPreferences.models.length < 1}
        title="Business model"
        icon={Model}
        handleSelect={(value, isChecked) =>
          handleSelect("models", value, isChecked)
        }
        selectAll={() => selectAll("models")}
        handleSave={() => setShowModelSelector(false)}
      />

      <PreferencesSelector
        open={showFocusSelector}
        options={options.types}
        selectedOptions={selectedPreferences.types}
        onClose={() => setShowFocusSelector(false)}
        error={selectedPreferences.types.length < 1}
        title="Product Focus"
        icon={Model}
        handleSelect={(value, isChecked) =>
          handleSelect("types", value, isChecked)
        }
        selectAll={() => selectAll("types")}
        handleSave={() => setShowFocusSelector(false)}
      />

      <PreferencesSelector
        open={showStagesSelector}
        options={options.stages}
        selectedOptions={selectedPreferences.stages}
        onClose={() => setShowStagesSelector(false)}
        error={selectedPreferences.stages.length < 1}
        title="Stages"
        icon={Stage}
        handleSelect={(value, isChecked) =>
          handleSelect("stages", value, isChecked)
        }
        selectAll={() => selectAll("stages")}
        handleSave={() => setShowStagesSelector(false)}
      />

{data.showTerms && <Terms toggleTerms={toggleTerms} />}

      <Modal open={showCountrySelector} border="no-border" size="lg">
        <div className="flex flex-col justify-center items-center w-full ">
          {data.formData.company.startupcountries.length>0 && <div className="flex items-end justify-end w-full px-2 text-silverPrairi ">
            <div
              onClick={() => setShowCountrySelector(false)}
              className="cursor-pointer"
            >
              x
            </div>
          </div>}
          <div className="mt-5 px-3 ">
            <label htmlFor="startupcountries">
              Acceptable countries/regions of startups{" "}
              {countriesError && (
                <span className="text-redPrairi font-bold">* required</span>
              )}
            </label>
            <div className="w-full my-2 ">
              <select
                className="border-blackish mt-2.5 w-full rounded border p-2.5 text-black outline-none"
                value={data.selectedStartupCountry}
                onChange={(e) => handleStartupCountryChange(e.target.value)}
                id="startupcountries"
              >
                {countriesRegion.map((countryRegion, index) => {
                  return (
                    <option
                      disabled={index === 0 ? true : false}
                      value={countryRegion}
                      key={countryRegion}
                    >
                      {countryRegion}
                    </option>
                  );
                })}
              </select>
            </div>
            {data.formData.company.startupcountries.length<1 && <div className="text-redPrairi ">Please select at least one country.</div>}
          </div>
          {data.formData.company.startupcountries.length > 0 && (
            <div className="my-5 px-3 w-full ">
              <div className="border-blackish  pb-5 w-full  flex flex-wrap rounded border  text-black bg-white overflow-y-auto max-h-48">
                {data.formData.company.startupcountries.map((countryName) => {
                  return (
                    <div>
                      <div
                        className={`${countryName===countryName.toUpperCase()?"text-[#f2f2f2] bg-[#262626]":""} border rounded border-blackish text-sm p-2 m-1`}
                        key={countryName}
                      >
                        <span className="mr-2">{countryName}</span>
                        <button
                          onClick={() => removeCountryHandler(countryName)}
                          className="bg-gray-700 px-1 py-0 text-white"
                        >
                          x
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-between items-center mt-4">
                <PrairiButton
                  size="sm"
                  label="Save"
                  click={() => setShowCountrySelector(false)}
                  bgColor="regular"
                  border="green"
                  textColor="whiteText"
                  rounded="soft"
                />
                <TextButton
                  label="Clear all"
                  color="silver"
                  click={() =>
                    setData({
                      ...data,
                      formData: {
                        ...data.formData,
                        company: {
                          ...data.formData.company,
                          startupcountries: [],
                        },
                      },
                      selectedStartupCountry: "Select countries / regions",
                    })
                  }
                />
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ExpressSignUp;