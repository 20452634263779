import { navBarContext, authContext } from "./context/context";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getFunctions, httpsCallable } from "firebase/functions";
import Logo from "../assets/prairiwhite.png";
import Flag from "../assets/flag.png";
import {
  NewStartup,
  Modal,
  PrairiButton,
  TextButton,
  Loading,
  InvitationPrompt,
  BugTab,
  Viewer
} from "@peterfosso/prairi-components";
import {
  collection,
  db,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
} from "../firebase/firebaseApp";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import axios from "axios";
import { sendBugEmails, useWindowDimensions } from "../utils";
//hi

const SharedMatch = (props) => {
  const { navBarHeight } = useContext(navBarContext);
  let { startupId, potentialId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 767 });
    const [viewerSrc, setViewerSrc] = useState("");
  let navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState(false);
  const [startupData, setStartupData] = useState({});
  const [investorData, setInvestorData] = useState({});
  const [potentialData, setPotentialData] = useState({});
  const [welcomePopup, setWelcomePopup] = useState(true);
  const [interestedPopUp, setInterestedPopUp] = useState(false);
  const [notInterestedPopUp, setNotInterestedPopUp] = useState(false);
  const [promptPopUp, setPromptPopUp] = useState(false);
  const [feedbackPopUp, setFeedbackPopUp] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [feedbackError, setFeedbackError] = useState("");
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState(null);
  const [bugError, setBugError] = useState(null);
  const { width, height } = useWindowDimensions();
  const deviceWidth = parseFloat(width);
  const deviceHeight = parseFloat(height);
  const redirectTo404 = () => {
    navigate("/404");
  };

  const handleUpload = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    if (!bugEmail || bugEmail.trim() === "") {
      setBugError("We need your email address.");
      return false;
    }
    return true;
  };

  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUpload({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(bugEmail, "", bugText, "Shared Match", uploadedUrl || "");
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  const getDataFromDb = async (startupId) => {
    try {
      const userRef = doc(db, "sharedUsers", startupId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        if (userDoc.data()) {
          setStartupData(userDoc.data());
          setViewerSrc(userDoc.data().formData.company.deck)
        }
      } else {
        redirectTo404();
        return;
      }
    } catch (error) {
      console.log(error); // redirectTo404();
    }
  };

  const getDataFromPotential = async (potentialId) => {
    try {
      const userRef = doc(db, "potential", potentialId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        if (userDoc.data()) {
          setPotentialData(userDoc.data());
        }
      } else {
        redirectTo404();
        return;
      }
    } catch (error) {
      console.log(error); // redirectTo404();
    }
  };

  const fetchData = async (startupId) => {
    // console.log("inside fetchData" + JSON.stringify(currentUser));
    try {
      await getDataFromDb(startupId);
      //await getInvestorDataFromDb(potentialData.sharedBy);
      await getDataFromPotential(potentialId);
      // await fetchMatches(matches);
      setDataFetched(true);
    } catch (error) {
      // Handle errors if any of the functions throw an error
      console.error("Error fetching data:", error);
    }
  };

  const handleInterested = async (startupId) => {
    const userRef = doc(db, "potential", potentialId);
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        let interestedMatches = [];
        if (userData.interestedMatches) {
          interestedMatches = userData.interestedMatches;
        }
        if (!interestedMatches.includes(startupData.userId)) {
          interestedMatches.push(startupData.userId);
          await updateDoc(userRef, { interestedMatches });
          setInterestedPopUp(true);
        } else {
          setInterestedPopUp(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleArchived = async (startupId) => {
    const userRef = doc(db, "potential", potentialId);
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        let archivedMatches = [];
        if (userData.archivedMatches) {
          archivedMatches = userData.archivedMatches;
        }
        if (!archivedMatches.includes(startupData.userId)) {
          archivedMatches.push(startupData.userId);
          await updateDoc(userRef, { archivedMatches });
          setInterestedPopUp(true);
        } else {
          setInterestedPopUp(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotInterested = async (startupId) => {
    const userRef = doc(db, "potential", potentialId);
    try {
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        let notInterestedMatches = [];
        if (userData.notInterestedMatches) {
          notInterestedMatches = userData.notInterestedMatches;
        }
        if (!notInterestedMatches.includes(startupData.userId)) {
          notInterestedMatches.push(startupData.userId);
          await updateDoc(userRef, { notInterestedMatches });
        }
        setNotInterestedPopUp(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(startupId);
    console.log(potentialData);
    //console.log(JSON.stringify(matchesData))
  }, [dataFetched]); //REPLACE WITH FUNCTIONS

  const handleOk = () => {
    setPromptPopUp(true);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const sendFeedbackEmail = async () => {
    const functions = getFunctions();
    const sendEmailFn = httpsCallable(functions, "sendEmail");
    if (!startupData.coleagueEmailConsent) {
      console.log("no email consent");
      return; // Do not send SMS if smsConsent is false
    }
    try {

      await sendEmailFn({
        templateId: 48,
        recipients: [
          {
            name: startupData.coleagueContactName,
            email: startupData.coleagueEmail,
          },
        ],
        params: {
          NAME: startupData.coleagueContactName.split(" ")[0],
          POTENTIALNAME: potentialData.username,
          STARTUP: startupData.formData.company.company,
          FEEDBACK: feedback,
        },
      });

    } catch (error) {
      console.log("caught error");
      console.log(error);
    }
  };

  const sendFeedbackSMS = async () => {
    const messagesCollection = collection(db, "messages");
    if (!startupData.coleagueSmsConsent) {
      console.log("no sms consent");
      return; // Do not send SMS if smsConsent is false
    }
    const dataToStore = {
      to: `+${startupData.coleaguePhone}`,
      body: `From PRAIRI: ${potentialData.username} sent feedback about ${startupData.formData.company.company}. Here's what they said: ${feedback}`,
    };
    await addDoc(messagesCollection, dataToStore);
  };

  const shareFeedback = () => {
    if (feedback === "") {
      setFeedbackError(
        "Please select one of the options above or type a message"
      );
      return;
    }
    sendFeedbackEmail();
    sendFeedbackSMS();
    setFeedbackPopUp(false);
    setPromptPopUp(true);
  };

  return (
    <div
      id="newMatch__main"
      style={{
        marginTop: navBarHeight,
        marginBottom: navBarHeight,
        width: "100%",
        minWidth: "366px",
      }}
      className=""
    >
      <BugTab
        email={bugEmail}
        setEmail={handleBugEmail}
        setText={handleBugText}
        submit={(file) => handleUploadAndSendEmail(file)}
        error={bugError}
        handleFileChange={handleUpload}
      />
      {!dataFetched && (
        <div className="pt-20">
          <Loading />
        </div>
      )}
      {dataFetched && (
        <div className="flex justify-center">
          {!isMobile && <section
            id="newMatch__container"
            className={`
         flex

        m-auto  rounded-md
        min-w-[380px]
       `}
          >
            <div className="w-1/3 px-0.5 bg-rainbowBg overflow-y-auto " style={{ maxHeight: deviceHeight * 0.8 }}>
              <NewStartup
                startup={startupData}
                interested={() => handleInterested(startupId)}
                notInterested={() => handleNotInterested(startupId)}
                archive={() => handleArchived(startupId)}
                shared
                investor={investorData}
                isMobile={isMobile}
              />
            </div>
            <div className="w-2/3">
            <Viewer src={viewerSrc} h={deviceHeight * 0.8} />
            </div>

            {!dataFetched && <Loading />}
          </section>}
          {isMobile && <section
            id="newMatch__container"
            className={`
         flex

        m-auto  rounded-md
        min-w-[380px]
       `}
          >
            <div className=" px-0.5 bg-rainbowBg ">
              <NewStartup
                startup={startupData}
                interested={() => handleInterested(startupId)}
                notInterested={() => handleNotInterested(startupId)}
                archive={() => handleArchived(startupId)}
                shared
                investor={investorData}
                isMobile={isMobile}
              />
            </div>


            {!dataFetched && <Loading />}
          </section>}

        </div>
      )}
      {dataFetched && (
        <Modal open={welcomePopup} size="md" border="silver">
          <div className="p-5 flex flex-col justify-center items-center">
            <img src={Logo} className="w-1/6 mb-5"></img>
            <div>
              <p className="mb-2">
                Hey, {potentialData.name}! Here's the profile of the startup
                recommended by {startupData.coleagueContactName}.
              </p>
              <p>Let them know what you think.</p>
            </div>

            <div className="mt-2">
              <PrairiButton
                label="OK"
                border="green"
                bgColor="regular"
                textColor="whiteText"
                click={() => setWelcomePopup(false)}
                size="sm"
                rounded="soft"
              />
            </div>
          </div>
        </Modal>
      )}
      <Modal open={interestedPopUp} border="silver" size="lg">
        <div className="p-2 flex flex-col justify-center">
          <div className="flex justify-end  text-sm pt-2">
            <TextButton
              color="silver"
              click={() => setInterestedPopUp(false)}
              label="Close"
            />
          </div>
          <div className="flex justify-center flex-col items-center">
            <img src={Logo} className="w-1/6 mb-5"></img>
            <p className="mb-2">
              OK, we'll save this match to your Dashboard as soon as you join
              PRAIRI (it's free to join).
            </p>

            <PrairiButton
              label="OK"
              border="silver"
              click={() => handleOk()}
              rounded="soft"
              size="sm"
            />
          </div>
        </div>
      </Modal>

      <Modal open={notInterestedPopUp} border="silver" size="lg">
        <div className="p-2 flex flex-col items-center justify-center">
          <img src={Logo} alt="Unicorn logo" className="w-1/4 mb-5" />
          <p className="mb-5">Thank you</p>
          <p className="mb-5">
            Do you wish to provide feedback to {investorData.contactName} about
            this match?
          </p>
          <div className="flex w-[90%] justify-between">
            <PrairiButton
              border="green"
              size="sm"
              rounded="soft"
              label="Yes"
              click={() => setFeedbackPopUp(true)}
            />
            <PrairiButton
              border="red"
              size="sm"
              rounded="soft"
              label="NO"
              click={() => handleOk()}
            />
          </div>
        </div>
      </Modal>
      <Modal open={promptPopUp} border="silver">
        <InvitationPrompt
          url={`http://prairi.com/invitation/investor/vsqBZMXiHUCiNEYSYQsi`}
          feedback={feedback !== ""}
        />
      </Modal>

      <Modal open={feedbackPopUp} border="silver">
        <div className="flex flex-col">
          <div className="flex mb-5 justify-end w-full text-sm ">
            <TextButton
              label="Close"
              color="green"
              click={() => setFeedbackPopUp(false)}
            />
          </div>
          <p className="font-bold mb-5">
            <span className=" text-bluePrairi">Feedback</span> to{" "}
            {investorData.contactName}
          </p>
          <p className="mb-2">From: {potentialData.username}</p>
          <div className="flex flex-col justify-start items-start text-silverPrairi">
            <p>{investorData.email}</p>
            <p className="mb-5">+{investorData.phone}</p>
          </div>

          <p className="mb-2">Message:</p>
          <div
            className={`mb-5 text-black bg-white p-2 rounded-md cursor-pointer ${
              feedback === "Yes, very interesting! And keep 'em coming :)" &&
              "border-[4px] border border-greenPrairi solid"
            }`}
            onClick={() =>
              setFeedback("Yes, very interesting! And keep 'em coming :)")
            }
          >
            <p>Yes, very interesting! And keep 'em coming :)</p>
          </div>
          <div
            className={`mb-5 text-black bg-white p-2 rounded-md cursor-pointer ${
              feedback ===
                "Thanks but it's not really something I'm interested in." &&
              "border-[4px] border border-greenPrairi solid"
            }`}
            onClick={() =>
              setFeedback(
                "Thanks but it's not really something I'm interested in."
              )
            }
          >
            <p>Thanks but it's not really something I'm interested in.</p>
          </div>

          <p className="mb-2">OR type a message:</p>

          <textarea
            className="w-full mb-5 text-black bg-white p-2 rounded-md"
            onChange={handleFeedbackChange}
            placeholder="Select from above or write your feedback..."
          />
          <p className="text-redPrairi">{feedbackError}</p>
          <div className="flex justify-center">
            <PrairiButton
              label="Send"
              border="red"
              rounded="full"
              size="md"
              click={shareFeedback}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SharedMatch;
