import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackgroundImg from "../assets/ad-blank-wide.jpg";
import { navBarContext } from "./context/context";
import Phone from "../assets/matchphone.png";
import { updateLatestActions, useWindowDimensions, sendBugEmails } from "../utils";
import { Loading, BugTab } from "@peterfosso/prairi-components";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import { db, getDoc, doc, updateDoc } from "../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
const Register = () => {
  const { navBarHeight } = useContext(navBarContext);
  let navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 800 });
  let { docId } = useParams();

  const { width, height } = useWindowDimensions();
  const deviceWidth = parseFloat(width);
  const deviceHeight = parseFloat(height);
  const huge = deviceWidth>2800;
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState(null);
    const [bugError, setBugError] = useState(null)


  const [data, setData] = useState({
    contactName: "",
    category: "",
  });

  const [loading, setLoading] = useState(true);
  const storeView = async (userId) => {
    const userRef = doc(db, "preUsers", userId); //We'll concat or something. But we're storing latest actions before registering in preUsers as well.
    updateLatestActions(userRef, "Invitation view", isMobile);
    //await updateDoc(userRef, {accessedRegisterForm: true})
  };

   const handleUpload = (e) => {
        return new Promise((resolve, reject) => {
          const selectedFile = e.target.files[0];
    
          const storageRef = ref(storage, `bugs/${selectedFile.name}`);
          const uploadTask = uploadBytesResumable(storageRef, selectedFile);
    
          uploadTask.on(
            "state_changed",
            null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
            (error) => { // Manejador de error
              console.error("Upload failed:", error);
              reject(error);
            },
            async () => { // Manejador de éxito
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
              console.log("uploaded correctly");
              setScreenshotUrl(downloadURL);
            }
          );
          
        });
      };
    
      const handleBugText = (e) => {
        const value = e.target.value;
        setBugText(value);
        if (value !== "") {
          setBugError("");
        }
      };
      
      const handleBugEmail = (e) => {
        const value = e.target.value;
        setBugEmail(value);
        if (value !== "") {
          setBugError("");
        }
      };
      
      const validateFields = () => {
        if (!bugText || bugText.trim() === "") {
          setBugError("Please describe the bug.");
          return false;
        }
        if (!bugEmail || bugEmail.trim() === "") {
          setBugError("We need your email address.");
          return false;
        }
        return true;
      };
      
    const handleUploadAndSendEmail = async (file) => {
      try {
        if (!validateFields()) return;
  
        let uploadedUrl = screenshotUrl;
        if (file) {
          uploadedUrl = await handleUpload({ target: { files: [file] } });
        }
  
        console.log(`screenshotUrl ${uploadedUrl}`);
        sendBugEmails(bugEmail, "", bugText, "Register", uploadedUrl || "");
      } catch (error) {
        console.error("Error uploading file or sending email:", error);
      }
    };
  

  useEffect(() => {
    window.scrollTo(0, 0);
    storeView(docId);
  }, []);

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const docRef = doc(db, "preUsers", docId); //now fetches from preUsers so it can read
        const docData = await getDoc(docRef);
        if (docData.exists()) {
          setData(docData.data());
        } else {
          //console.log("No such document!");
        }
        setLoading(false);
      } catch (error) {
        //console.error("Error fetching document:", error);
        setLoading(false);
      }
    };

    fetchDocumentData();
  }, [docId]);

  useEffect(() => {
    if ((!loading && data.category === "startup" && !data.codeGeneratedAt) || (!loading && data.category === "investor" && !data.codeGeneratedAt)) {
      navigate(`/invited/${docId}`);
    }
    if (!loading && data.codeGeneratedAt && data.category==="investor"){
      navigate(`/discover/${docId}`)
    }
    if (!loading && data.codeGeneratedAt && data.category==="startup"){
      navigate(`/getfunded/${docId}`)
    }
  }, [data, docId, loading, navigate]);

  const getId = async () => {
    // get code and email from docId params
    if (docId && isNaN(Number(docId))) {
      try {
        const docRef = doc(db, "preUsers", docId);

        const docSnap = await getDoc(docRef);
        const { username: name, email, category, registered } = docSnap.data();
        setData({
          ...data,
          email,
          category,
          contactName: name.split(" ")[0],
        });
      } catch (error) {
        setData({
          ...data,
        });
      }
    } else {
      setData({ ...data, code: docId, codeComplete: true });
    }
  };

  useEffect(() => {
    getId();
    //console.log(location);
  }, [docId]);

  const { username, contactName, category } = data;


    return (
      <div className="mt-[100px]">
        <Loading />
      </div>
    ); // Mostrar una pantalla de carga mientras se obtienen los datos
  

  
};

export default Register;
