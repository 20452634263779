import React, { useState, useRef, useEffect, useContext } from "react";
import { navBarContext, authContext } from "../components/context/context"
import Home from "./Home";
import SectionThreeLandingPage from "./LandingPage/SectionThreeLandingPage";
import SectionTwoLandingPage from "./LandingPage/SectionTwoLandingPage";
import BuildButton from "../assets/build-a-startup-button.png"
import { getAnalytics, logEvent } from 'firebase/analytics';
import { CookiesBanner } from "@peterfosso/prairi-components";
import { analytics } from "../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Modal } from "@peterfosso/prairi-components";
import { query, collection, db, where, getDocs } from "../firebase/firebaseApp";
import { updateButtonClickCount, sendBugEmails } from "../utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import { BugTab } from "@peterfosso/prairi-components";

const Landing = () => {
  const [data, setData] = useState({ showCodeMenu: false, category: "" });
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const { navBar } = useContext(navBarContext);
  const { currentUser } = useContext(authContext);
  const [userData, setUserData] = useState({})
  const [dataFetched, setDataFetched] = useState(false)
  const [welcomePopup, setWelcomePopup] = useState(false);
  const [redirectedFromLandingPage, setRedirectedFromLandingPage] = useState(false);
      const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
      const [bugEmail, setBugEmail] = useState("");
      const [bugText, setBugText] = useState("");
        const [bugError, setBugError] = useState(null)
  const landingPageRef = useRef();
  const prairiTextRef = useRef();
  const privateRef = useRef();
  const buttonsRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let navigate = useNavigate();
  const toggleCodeMenu = (categoryName) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (categoryName) {
      setData({ showCodeMenu: !data.showCodeMenu, category: categoryName });
    } else {
      setData({ showCodeMenu: !data.showCodeMenu, category: "" });
    }
  };

  const getDataFromDb = async () => {
    try {
      // Get user data from email
      const email = currentUser?.email;
      const q = query(collection(db, "users"), where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let userData;
      let userId;
      querySnapshot.forEach((doc) => {
        userData = doc.data();
        userId = doc.id;
      });
      return { userData, userId };
    } catch (error) {
      // console.log(error);
      return { userData: null };
    }
  };

    const handleUpload = (e) => {
      return new Promise((resolve, reject) => {
        const selectedFile = e.target.files[0];
  
        const storageRef = ref(storage, `bugs/${selectedFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, selectedFile);
  
        uploadTask.on(
          "state_changed",
          null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
          (error) => { // Manejador de error
            console.error("Upload failed:", error);
            reject(error);
          },
          async () => { // Manejador de éxito
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
            console.log("uploaded correctly");
            setScreenshotUrl(downloadURL);
          }
        );
        
      });
    };
  
    const handleBugText = (e) => {
      const value = e.target.value;
      setBugText(value);
      if (value !== "") {
        setBugError("");
      }
    };
    
    const handleBugEmail = (e) => {
      const value = e.target.value;
      setBugEmail(value);
      if (value !== "") {
        setBugError("");
      }
    };
    
    const validateFields = () => {
      if (!bugText || bugText.trim() === "") {
        setBugError("Please describe the bug.");
        return false;
      }
      return true;
    };
    
    const handleUploadAndSendEmail = async (file) => {
      try {
        if (!validateFields()) return;
    
        let uploadedUrl = screenshotUrl;
        if (file) {
          uploadedUrl = await handleUpload({ target: { files: [file] } });
        }
    
        console.log(`screenshotUrl ${uploadedUrl}`);
        sendBugEmails(
          userData.email,
          userData.contactName,
          bugText,
          "Landing registered",
          uploadedUrl || ""
        );
      } catch (error) {
        console.error("Error uploading file or sending email:", error);
      }
    };

  useEffect(() => {
    // Check if the consent cookie or local storage variable exists
    const hasConsent = localStorage.getItem('cookieConsent');
    // If there's no consent, display the cookie banner
    if (!hasConsent) {
      setShowCookieBanner(true);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { userData } = await getDataFromDb();
        setUserData(userData)
        setDataFetched(true)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentUser]);


//   useEffect(() => {
//     window.scrollTo({ top: 0, left: 0});
//     if (currentUser){
//       setRedirectedFromLandingPage(true)
//         navigate('/dashboard')  
//     }

//   }, [currentUser]);

  // Inside your CookieConsentBanner component
const handleAcceptClick = () => {
  localStorage.setItem('cookieConsent', 'true');
  setShowCookieBanner(false);
  //console.log("click")
  // Close the banner or perform any other necessary actions
};


  useEffect(() => {
    const solidNavBg = [
     
      "from-backgroundPrairi",
      "to-backgroundPrairi",
      // "shadow-lg",
      // "shadow-black/60",
    ];
    const transparentNavBg = "bg-transparent"

    const updateNavBg = () => {
      
      const { top } = landingPageRef.current.getBoundingClientRect();
      const logoIcon = navBar.current.getElementsByTagName("img")[0];
      const smallLogo = ["h-12", "md:h-14", "mr-2"];
      const bigLogo = ["h-16", "md:h-16", "lg:h-20", "mr-3"];

      if (top) {
        navBar.current.classList.remove(transparentNavBg);
        navBar.current.classList.add("bg-black", "shadow-lg");
        logoIcon.classList.remove(...bigLogo);
        logoIcon.classList.add(...smallLogo);
      } else {
        navBar.current.classList.remove("bg-black", "shadow-lg");
        navBar.current.classList.add(transparentNavBg);
        logoIcon.classList.remove(...smallLogo);
        logoIcon.classList.add(...bigLogo);
      }
    };

    updateNavBg();
    document.addEventListener("scroll", updateNavBg);

    let navBarRef = navBar.current;
    let prairiText = prairiTextRef.current;
    let privateText = privateRef.current;
    let buttonsContainer = buttonsRef.current;
    prairiText.classList.remove("translate-y-3/4");
    prairiText.classList.add("translate-y-0");
    privateText.classList.remove("translate-y-3/4");
    privateText.classList.add("translate-y-0");
    buttonsContainer.classList.remove("translate-y-3/4");
    buttonsContainer.classList.add("translate-y-0");
    return () => {
      document.removeEventListener("scroll", updateNavBg);
      navBarRef.classList.remove(transparentNavBg);
      navBarRef.classList.add(...solidNavBg);
    };
  }, [data.showCodeMenu, navBar]);

  const handleStartupClick = () => {
    // Log the button click event
    updateButtonClickCount("Landing Startup")
    logEvent(analytics, 'button_click', {
      button_name: 'landing_startup' // You can customize this field
    });
    
  };

  useEffect(() => {
    const button = document.getElementById('landing-startup'); 
    if (button) {
      button.addEventListener('click', handleStartupClick);
    }
    return () => {
      if (button) {
        button.removeEventListener('click', handleStartupClick);
      }
    };
  }, []);

  const handleInvestorClick = () => {
    // Log the button click event
    updateButtonClickCount("Landing Investor")
    logEvent(analytics, 'button_click', {
      button_name: 'landing_investor' // You can customize this field
    });
  };

  useEffect(() => {
    const button = document.getElementById('landing-investor'); 
    if (button) {
      button.addEventListener('click', handleInvestorClick);
    }
    return () => {
      if (button) {
        button.removeEventListener('click', handleInvestorClick);
      }
    };
  }, []);

  const handleBuildClick = () => {
    updateButtonClickCount("Build a Startup banner")
    navigate("/buildastartup")
  }


  return (
    <div ref={landingPageRef} >
                           {userData && (
                             <BugTab
                               email={userData.email}
                               name={userData.contactName}
                               setEmail={handleBugEmail}
                               setText={handleBugText}
                               submit={(file) =>
                                 handleUploadAndSendEmail(file)
                               }
                               error={bugError}
                               handleFileChange={handleUpload}
                             />
                           )}
      <section className={`w-full h-screen relative flex flex-col justify-center items-center ${!data.showCodeMenu && "bg-mobileLanding "} lg:bg-firstSection bg-cover bg-center `}>
        <h1
          ref={prairiTextRef}
          className="uppercase text-center  text-4xl md:text-7xl font-bold my-2 translate-y-3/4 transition-all duration-1000 ease-in-out "
        >
          P R A I R I
        </h1>
        <p
          ref={privateRef}
          className=" italic text-center text-xl md:text-2xl w-10/12 my-2 translate-y-3/4 transition-all duration-1000 ease-in-out"
        >
          {isMobile && (
            <div>
              <br />
              The Global Platform Matching
              <br />
              Startups & Investors
            </div>
          )}
          {!isMobile && (
            <div>The Global Platform Matching Startups & Investors</div>
          )}
        </p>
        <div
          ref={buttonsRef}
          className="flex items-center justify-center w-full md:w-7/12  px-4 mt-4 mb-10 md:my-10 text-white text-lg lg:text-2xl translate-y-3/4 transition-all duration-1000 ease-in-out "
        >
          <button
            onClick={() => navigate("/getfunded")}
            id='landing-startup'
            className="py-3  px-12  rounded-full  bg-startupBtn hover:bg-investorBtn mr-4"
          >
            Startup
          </button>
          <button
            onClick={() => navigate("/discover")}
            id='landing-investor'
            className="py-3  px-12  rounded-full bg-startupBtn hover:bg-investorBtn ml-4 "
          >
            Investor
          </button>
          
        </div>
        {!data.showCodeMenu && <div className="lg:w-full w-3/4 absolute bottom-5 lg:bottom-0 left-0 z-0 p-4 ">
        <img src={BuildButton} className="object-cover cursor-pointer ml-4 mb-4 " alt="Build A Startup link" onClick={handleBuildClick}></img>
        </div>}
        
        {showCookieBanner && <CookiesBanner click={handleAcceptClick}/>}

      </section>
      {<SectionTwoLandingPage show={data.showCodeMenu}/>}

     {!data.showCodeMenu && <SectionThreeLandingPage
        sectionThreeLandingPageProps={{ toggleCodeMenu }}  
      />} 


      <Modal open={data.showCodeMenu}>
      <Home homeProps={{ toggleCodeMenu }} category={data.category} />
      </Modal>

    </div>
  );
};

export default Landing