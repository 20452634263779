import React, { useState, useRef, useEffect, useContext } from "react";
import { navBarContext, authContext } from "../components/context/context"
import Home from "./Home";
import SectionThreeLandingAd from "./LandingPage/SectionThreeLandingAd";
import SectionTwoLandingAd from "./LandingPage/SectionTwoLandingAd";
import BuildButton from "../assets/build-a-startup-button.png"
import { getAnalytics, logEvent } from 'firebase/analytics';
import { CookiesBanner } from "@peterfosso/prairi-components";
import { analytics } from "../firebase/firebaseApp";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Modal } from "@peterfosso/prairi-components";
import { query, collection, db, where, getDocs } from "../firebase/firebaseApp";
import { updateButtonClickCount, sendBugEmails } from "../utils";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebaseApp";
import { BugTab } from "@peterfosso/prairi-components";

const LandingAd = () => {
  const [data, setData] = useState({ showCodeMenu: false, category: "" });
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const { navBar } = useContext(navBarContext);

  const [userData, setUserData] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [welcomePopup, setWelcomePopup] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState(null);
  const [bugError, setBugError] = useState(null);
  const [redirectedFromLandingPage, setRedirectedFromLandingPage] =
    useState(false);
  const landingPageRef = useRef();
  const prairiTextRef = useRef();
  const privateRef = useRef();
  const buttonsRef = useRef();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  let navigate = useNavigate();
  const toggleCodeMenu = (categoryName) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (categoryName) {
      setData({ showCodeMenu: !data.showCodeMenu, category: categoryName });
    } else {
      setData({ showCodeMenu: !data.showCodeMenu, category: "" });
    }
  };


  useEffect(() => {
    // Check if the consent cookie or local storage variable exists
    const hasConsent = localStorage.getItem("cookieConsent");
    // If there's no consent, display the cookie banner
    if (!hasConsent) {
      setShowCookieBanner(true);
    }
  }, []);


  // Inside your CookieConsentBanner component
  const handleAcceptClick = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowCookieBanner(false);
    //console.log("click")
    // Close the banner or perform any other necessary actions
  };

  useEffect(() => {
    const solidNavBg = [
      "from-backgroundPrairi",
      "to-backgroundPrairi",
      // "shadow-lg",
      // "shadow-black/60",
    ];
    const transparentNavBg = "bg-transparent";

    const updateNavBg = () => {
      const { top } = landingPageRef.current.getBoundingClientRect();
      const logoIcon = navBar.current.getElementsByTagName("img")[0];
      const smallLogo = ["h-12", "md:h-14", "mr-2"];
      const bigLogo = ["h-16", "md:h-16", "lg:h-20", "mr-3"];

      if (top) {
        navBar.current.classList.remove(transparentNavBg);
        navBar.current.classList.add("bg-black", "shadow-lg");
        logoIcon.classList.remove(...bigLogo);
        logoIcon.classList.add(...smallLogo);
      } else {
        navBar.current.classList.remove("bg-black", "shadow-lg");
        navBar.current.classList.add(transparentNavBg);
        logoIcon.classList.remove(...smallLogo); 
        logoIcon.classList.add(...bigLogo);
      }
    };

    updateNavBg();
    document.addEventListener("scroll", updateNavBg);

    let navBarRef = navBar.current;
    let prairiText = prairiTextRef.current;
    let privateText = privateRef.current;
    let buttonsContainer = buttonsRef.current;
    prairiText.classList.remove("translate-y-3/4");
    prairiText.classList.add("translate-y-0");
    privateText.classList.remove("translate-y-3/4");
    privateText.classList.add("translate-y-0");
    buttonsContainer.classList.remove("translate-y-3/4");
    buttonsContainer.classList.add("translate-y-0");
    return () => {
      document.removeEventListener("scroll", updateNavBg);
      navBarRef.classList.remove(transparentNavBg);
      navBarRef.classList.add(...solidNavBg);
    };
  }, [data.showCodeMenu, navBar]);


  return (
    <div ref={landingPageRef}>
      <section
        className={`w-full h-screen relative flex flex-col justify-start pt-[100px] items-center ${
          !data.showCodeMenu && "bg-mobileLanding "
        } lg:bg-firstSection bg-cover bg-center `}
      >
        {!isMobile && <div
          ref={prairiTextRef}
          className={`text-center text-4xl  mt-2 translate-y-3/4 transition-all duration-1000 ease-in-out `}
        >
            <div className={`text-4xl mb-2`}>Tired of struggling to find investors?</div>
            <div className={`text-5xl mb-2`}>Join the global platform where</div>
            <div className={`mb-[80px] text-6xl`}>Top Investors discover startups like YOURS</div>

        </div>}
        {isMobile && <div
          ref={prairiTextRef}
          className={`text-center text-2xl  mt-2 translate-y-3/4 transition-all duration-1000 ease-in-out `}
        >
            <div className={` my-2 `}>Tired of struggling to find investors?</div>
            <div className={`text-3xl`}>Join the global platform where</div>
            <div className={`mb-[80px] text-3xl mt-2`}>Top Investors discover startups like YOURS</div>

        </div>}
        <div className={`  italic ${isMobile?"text-sm pt-4":"text-lg"} `}><span className="not-italic font-bold mr-2">P R A I R I  </span>is seamlessly matching Investors with Startups</div>
        <p
          ref={privateRef}
          className=" italic text-center text-xl md:text-2xl w-10/12  translate-y-3/4 transition-all duration-1000 ease-in-out"
        >

        </p>
        <div
          ref={buttonsRef}
          className={`flex items-center justify-center w-full md:w-7/12  px-4  mb-10  text-white  lg:text-2xl translate-y-3/4 transition-all duration-1000 ease-in-out `}
        >
       <button className={`font-bold ${isMobile?"text-lg p-2":"text-xl p-4"} mt-12 rounded-full text-[#f2f2f2] bg-[#303030] border-2 solid  border-greenPrairi `} onClick={()=>navigate("/startuphome")}>
        Unlock Global Access to Investors
        </button>
        </div>


        {showCookieBanner && <CookiesBanner click={handleAcceptClick} />}
      </section>
      {<SectionTwoLandingAd show={data.showCodeMenu} />}

      {!data.showCodeMenu && (
        <SectionThreeLandingAd
          sectionThreeLandingPageProps={{ toggleCodeMenu }}
        />
      )}

    </div>
  );
};

export default LandingAd;
