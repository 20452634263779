import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  TextButton,
  Bullet,
  NotValid,
  PrairiButton,
  BugTab,
} from "@peterfosso/prairi-components";
import {
  updateButtonClickCount,
  generateRandomString,
  sendBugEmails,
} from "../utils";
import PhoneImage from "../assets/startupmatch2.png";
import Join from "../assets/join.png";
import { navBarContext } from "./context/context";
import { loadStripe } from "@stripe/stripe-js";
import { useMediaQuery } from "react-responsive";
import Invitation from "./Invitation";
import {
  collection,
  db,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  Timestamp,
  addDoc,
  getDoc,
} from "../firebase/firebaseApp";
import { storage } from "../firebase/firebaseApp";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const InvestorHome = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(null);
  const [code, setCode] = useState("");
  const { navBarHeight } = useContext(navBarContext);
  const [validPopup, setValidPopup] = useState(false);
  const [inputCode, setInputCode] = useState("");
  const [codeStatsId, setCodeStatsId] = useState(null);
  const [screenshotUrl, setScreenshotUrl] = useState(""); //for bug report
  const [bugEmail, setBugEmail] = useState("");
  const [bugText, setBugText] = useState(null);
  const [bugError, setBugError] = useState(null);
  const stripePromise = loadStripe(
    "pk_live_51P3Ify00vN5ANT6HieNrKMlp3npEccqmc0E85fOyxSGeI5PE5W2OLeiCXny2pGY8sRSCnG0vj22pMX1XFEoRs3Uu00y9nYHwha"
  );
  const [message, setMessage] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [promoCodes, setPromoCodes] = useState([]);
  const [error, setError] = useState(false);
  const [remainingTries, setRemainingTries] = useState(() => {
    const storedTries = parseInt(localStorage.getItem("remainingTries"), 10);
    return isNaN(storedTries) ? 3 : storedTries;
  });
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const fetchPromoCodes = async () => {
      const promoCodesCollection = collection(db, "promoCodes");
      const snapshot = await getDocs(promoCodesCollection, {
        fromCache: false,
      });
      const promoCodesList = snapshot.docs.map((doc) => doc.data().code);
      setPromoCodes(promoCodesList);
      //console.log('Promo Codes:', promoCodesList);
    };
    fetchPromoCodes();
  }, []);

  //Handle input value change
  const handleChange = (e) => {
    setCode(e.target.value);
  };

  const handleUploadBug = (e) => {
    return new Promise((resolve, reject) => {
      const selectedFile = e.target.files[0];

      const storageRef = ref(storage, `bugs/${selectedFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        null, // Manejador de progreso (puedes dejarlo como `null` si no necesitas reportarlo)
        (error) => {
          // Manejador de error
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          // Manejador de éxito
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
          console.log("uploaded correctly");
          setScreenshotUrl(downloadURL);
        }
      );
    });
  };

  const handleBugText = (e) => {
    const value = e.target.value;
    setBugText(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const handleBugEmail = (e) => {
    const value = e.target.value;
    setBugEmail(value);
    if (value !== "") {
      setBugError("");
    }
  };

  const validateFields = () => {
    if (!bugText || bugText.trim() === "") {
      setBugError("Please describe the bug.");
      return false;
    }
    if (!bugEmail || bugEmail.trim() === "") {
      setBugError("We need your email address.");
      return false;
    }
    return true;
  };

  const handleUploadAndSendEmail = async (file) => {
    try {
      if (!validateFields()) return;

      let uploadedUrl = screenshotUrl;
      if (file) {
        uploadedUrl = await handleUploadBug({ target: { files: [file] } });
      }

      console.log(`screenshotUrl ${uploadedUrl}`);
      sendBugEmails(bugEmail, "", bugText, "StartupHome", uploadedUrl || "");
    } catch (error) {
      console.error("Error uploading file or sending email:", error);
    }
  };

  const handleGoClick = async () => {
    const promoCode = promoCodes.find((code) => code === inputCode);
    if (promoCode) {
      const docRef = await addDoc(collection(db, "codeStats"), {
        code: inputCode,
        inputAt: Timestamp.fromDate(new Date()),
        used: false,
      });
      try {
        const q = query(
          collection(db, "promoCodes"),
          where("code", "==", inputCode)
        );
        const querySnapshot = await getDocs(q);
        const docId = querySnapshot.docs[0].id;
        let codeRef = doc(db, "promoCodes", docId);
        const docData = (await getDoc(codeRef)).data();
        await updateDoc(codeRef, { users: docData.users + 1 });
        setCodeStatsId(docRef.id);
        navigate(`/invitation/investor/${docRef.id}`, {
          state: { promo: inputCode, discount: docData.discount },
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setValidPopup(true);
    }
  };

  const navigate = useNavigate();

  return (
    <section className="bg-invitation  w-full  bg-cover bg-center bg-fixed">
      <div
        style={{
          marginTop: navBarHeight + 20,
          marginBottom: navBarHeight,
          height: "100%",
          width: "100%",
        }}
        className={`
      min-w-[366px]
      mx-auto  w-2/5
      text-md max-w-lg rounded-lg
      text-white `}
      >
        <BugTab
          email={bugEmail}
          setEmail={handleBugEmail}
          setText={handleBugText}
          submit={(file) => handleUploadAndSendEmail(file)}
          error={bugError}
          handleFileChange={handleUploadBug}
        />
        <div
          data-testid="testHome"
          className=" bg-[#1e1e1d]   md:max-w-lg mx-auto rounded-3xl mt-2 pt-5 pr-7  text-white"
        >
          <div className="flex justify-end mb-5">
            <TextButton
              color="yellow"
              click={() => navigate("/")}
              label="Close"
            />
          </div>

          <div>
            <div className="pl-7 mb-2">
              <Bullet
                text={
                  <div>
                    You'll be matched and notified when a startup exactly
                    matches your criteria.
                  </div>
                }
              />
              <br></br>
              <Bullet
                text={
                  <div>
                    All profiles are private and hidden from the public.
                  </div>
                }
              />
              <br></br>
              <Bullet
                text={
                  <div>
                    There is{" "}
                    <span className="font-bold">nothing to search</span> on
                    PRAIRI. We do all of that for you so you can instead spend
                    the time making more deals and growing your portfolio.
                  </div>
                }
              />
              <br></br>
            </div>
            {
              <div className={`flex ${isMobile ? "" : ""}`}>
                <img
                  src={PhoneImage}
                  alt="Phone "
                  className={`rounded-xl ${
                    !isMobile && "w-[295px] h-[392px]"
                  } ${isMobile && "w-[255px] h-[341px]"}`}
                ></img>
                <div className={`${!isMobile && "mt-5"} `}>
                  <div className="flex flex-col items-center ">
                    <img
                      alt="Join now"
                      src={Join}
                      className={`${!isMobile && "w-[120px]"} ${
                        isMobile && "w-[95px]"
                      }`}
                    ></img>
                  </div>
                  <div className="flex flex-col justify-center items-center text-center text-xl mt-5 lg:w-[110%]">
                    $1200/year
                  </div>

                  <div className="text-center self-end ">
                    <input
                      type="button"
                      className={`uppercase bg-greenPrairi cursor-pointer w-12 h-12 my-4 text-[black] border-gray-500 border rounded-full text-sm ml-2 outline-none ${
                        disabled ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      value="Enter"
                      onClick={() => navigate("/invitation/investor/stripe")}
                      disabled={disabled}
                    ></input>
                  </div>
                  <div className="flex flex-col text-center items-center lg:mt-6 mt-2 text-xs ">
                    <div>
                      {" "}
                      Or if you have a{" "}
                      <span className="font-bold">promo code,</span> enter it
                      here
                    </div>

                    <div classname={isMobile && "flex"}>
                      <input
                        type="text"
                        className="w-[80px] lg:w-[100px] h-[20px] lg:h-[30px] lg:mt-2 mt-2 rounded-md text-[black] bg-customWhite"
                        value={inputCode}
                        onChange={(e) =>
                          setInputCode(e.target.value.toUpperCase())
                        }
                      ></input>
                      {inputCode!==""&&<input
                        type="button"
                        className={`uppercase  cursor-pointer w-8 h-8  border-gray-500 border rounded-full text-sm ml-2 outline-none cursor-not-allowed bg-[#000000] text-[white]${
                          inputCode !== "" ? " bg-greenPrairi text-black" : ""
                        }`}
                        value="Go"
                        disabled={inputCode === ""}
                        onClick={handleGoClick}
                      ></input>}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        <div className="flex flex-col justify-center items-center">
          <NotValid click={() => setValidPopup(false)} open={validPopup} />
        </div>
        <div className=" text-xs text-justify">
          *NOTE: How many matches you receive depends on the criteria you set,
          how many startups match those preferences, and the number of startups
          on our platform at any given time.
          <br />
          <br />
          PRAIRI reserves the right to expand its services and offerings at any
          time and to charge for them accordingly, however, you will, of course,
          not be required to purchase them.{" "}
        </div>
      </div>
    </section>
  );
};

export default InvestorHome;
